// main-nav styles
.nav {
	display: none;
	position: relative;

	> .container {
		position: static;
	}

	li {
		a {
			&:focus {
				border: none;
				// outline: none;
			}
		}
	}

	&__lvl1 {
		display: inline-block;
		@extend %list-unstyled;

		> li {
			display: inline-block;
			padding: 0 5px;
			cursor: pointer;

			> a {
				color: $text-black;
				text-decoration: none;
				font-size: calc-em(14px);
				letter-spacing: 0;
				font-weight: 600;
				padding: 20px 0;
				line-height: 18px;
				display: block;
				cursor: pointer;

				&.active {
					color: $cccs-blue;
				}
			}

			&.hovered {
				.nav-megamenu {
					opacity: 1;
					visibility: visible;
				}

				.nav__link {
					color: $cccs-blue;
					// &:after {
					// 	opacity: 1;
					// }
				}
			}
		}

		.nav__link {
			position: relative;

			// &:after {
			// 	content: '';
			// 	display: block;
			// 	position: absolute;
			// 	bottom: 0;
			// 	left: 80%;
			// 	border-left: 8px solid transparent;
			// 	border-right: 8px solid transparent;
			// 	border-bottom: 8px solid $cccs-blue;
			// 	opacity: 0;
			// 	transform: translateX(-50%);
			// 	@include transition(opacity .35s ease-in-out);
			// }
		}
	}

	.nav-megamenu {
		position: absolute;
		z-index: 100;
		width: 100%;
		overflow: hidden;
		background: #fff;
		// background: $black-box;
		// color: #fff;
		// background: #fff;


		&__row {
			@extend %clearfix;
		}

		.nav__lvl2 {
			@extend %list-unstyled;
		}

		.lvl2-name {
			color: $cccs-yellow;
			text-decoration: none;
			line-height: 24px;
			font-weight: 500;
			font-size: calc-em(14px);
		}

		&__listing {
			border-top: 1px solid #fff;
			margin-top: 22px;
			padding-top: 25px;
			@extend %list-unstyled;

			> li {
				display: block;
				margin-bottom: 3px;

				> a {
					color: #303030;
					text-decoration: none;
					font-size: calc-em(14px);
				}

				&:last-child {
					margin-bottom: 44px;
				}
			}
		}
	}

	&__right {
		ul {
			@extend %list-unstyled;
		}
	}
}

@media only screen and (max-width: $bpDesktop - 1) {
	.nav {
		background: #fff;
		display: none;
		// height: calc(100vh - 300px);
		// overflow-y: scroll;
		// -webkit-overflow-scrolling:touch;

		.nav__lvl1 {
			display: block;

			> li {
				display: block;
				padding: 0;
				border-bottom: 1px solid $nav-divider;

				> a {
					color: $text-black;
					font-size: calc-em(14px);

					> i {
						display: inline-block !important;
						position: absolute;
						line-height: 24px;
						right: 0;
						font-size: calc-em(12px);
						top: 50%;
						transform: translate(0, -50%);
						@include transition(all .35s ease-in-out);
						width: 30px;

						&:before {
							position: relative;
							right: -10px;
						}
					}
				}

				&.is-shown {
					> a {
						border-bottom: none;
						padding-bottom: 27px;

						.icon-chevron-down {
							@include transition(all .35s ease-in-out);
							transform: translate(0, -50%) rotate(180deg);
						}
					}
				}

				&:last-child {
					> a {
						border: none;
					}

					&.is-shown {
						.nav-megamenu {
							border: none;
						}
					}
				}

				&:last-child {
					border-bottom: none;
				}
			}
		}

		.nav-megamenu {
			position: relative;
			display: none;
			background-color: $cccs-blue;

			.container {
				margin: 0 -20px;
			}

			.nav__lvl2 {
				> li {
					&.is-shown {
						> a {
							.icon-chevron-down {
								@include transition(all .35s ease-in-out);
								transform: translate(0, -50%) rotate(180deg);
							}
						}

						.lvl2-name {
							// padding-bottom: 20px;
							background-color: $text-black;
						}

						.nav-megamenu__columns {
							padding: 12px 24px 24px 36px;
							background: #fff;
						}

						.nav-megamenu__listing {
							> li {
								> a {
									color: $text-black;
								}
							}
						}
					}
				}
			}

			.lvl2-name {
				display: block;
				position: relative;
				// padding-bottom: 30px;
				color: #fff;
				padding: 14px 12px 14px 18px;

				span {
					padding-right: 40px;
					// padding-right: 20px;
					display: block;
				}

				> .icon-chevron-down {
					@include transition(all .35s ease-in-out);
					display: inline-block !important;
					position: absolute;
					line-height: 24px;
					right: 18px;
					font-size: calc-em(12px);
					top: 50%;
					transform: translate(0, -50%);
					width: 30px;


					&:before {
						position: relative;
						right: -10px;
					}
				}
			}

			&__listing {
				display: none;
				margin: 0;
				padding: 0;
				border: none;

				> li {
					margin-bottom: 20px;

					> a {
						font-size: calc-em(14px);
						color: #fff;
					}

					&:last-child {
						margin-bottom: 20px;
					}
				}
			}

			&__columns {
				padding: 0px 24px 0px 36px;

				&--first {
					.nav-megamenu__listing {
						> li {
							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.nav {
		display: block;

		&__lvl1 {
			margin: 0;
			padding-right: 45px;

			> li {
				// width: calc(100% / 8);
				width: calc(100% / 7.3);
				word-break: break-word;
				padding: 0 5px;

				.is-ie & {
					width: 13.698630137%;
				}

				> a {
					font-size: calc-em(14px);
					text-align: center;
				}

				&.height-overshot {
					padding: 0 12px;
					width: calc(100% / 7.2);

					.scroll-wrapper {
						top: 76px !important;
					}
				}

				&.height-twoline {
					padding: 0 12px;
					width: calc(100% / 7.2);

					// .scroll-wrapper {
					// 	top: 76px !important;
					// }
				}

				&.height-threeline {
					// padding: 0 12px;
					// width: calc(100% / 7.2);

					.scroll-wrapper {
						top: 94px !important;
					}
				}
			}
		}

		.nav-megamenu {
			@include transition(opacity .35s ease-in-out, visibility .35s ease-in-out);
			left: -180px;
			opacity: 0;
			top: 94px;
			// top: 94px;
			// width: calc(100% + 180px);
			width: 100vw;
			margin-left: -200px;
			visibility: hidden;
			// padding: 10px 0;
			box-shadow: 0 0 6px 1px rgba(100, 100, 100, 0.2);
			background: $cccs-blue;

			// .is-ie & {
			// 	top: 58px;
			// }

			.nav__lvl2 {
				> li {
					padding: 14px 38px 14px 220px;
					// padding: 14px 38px 14px 285px;
					// padding: 19px;
					width: calc(100% / 2.2);

					&:before {
						content: "";
						position: absolute;
						left: 0;
						top: 0;
						width: 210px;
						background: $cccs-blue;
						height: 100%;
					}

					.icon-circle-down {
						transform: translate(0, -50%) rotate(-90deg);
					}

					&.clicked {
						background-color: $text-black;

						> a {
							position: relative;

							&:after {
								content: '';
								display: block;
								position: absolute;
								top: 50%;
								right: -45px;
								// right: -8px;
								border-top: 8px solid transparent;
								border-bottom: 8px solid transparent;
								border-left: 8px solid $text-black;
								opacity: 1;
								transform: translate(0, -50%);
								z-index: 99000;
								// transform: translateX(-50%);
								// @include transition(opacity .35s ease-in-out);
							}

							&.no-lvl2 {
								&:after {
									opacity: 0;
								}
							}
						}

						.icon-circle-down {
							display: none !important;
						}
					}
				}
			}

			.nav__lvl2 {
				position: relative;
				min-height: 180px;
			}

			&__columns {
				display: none;
				position: absolute;
				top: -10px;
				left: calc(100% / 2.2);
				width: 100%;
				// width: 84%;
				// width: 58%;
				// width: calc(100% - 25%);
				background-color: #fff;
				// height: 200px;
				min-height: 150px;
				padding: 10px 0 10px 20px;
				// padding-left: 20px;

				&--first {
					width: 35%;
					display: inline-block;
					float: left;
				}

				&--second {
					width: 35%;
					display: inline-block;
					padding-left: 20px;
				}
			}

			&__listing {
				border-top: none;
				margin-top: 0;
				padding-top: 10px;
				padding-left: 16px;

				> li {
					margin-bottom: 0;

					> .lvl3-name {
						padding: 8px 0;
						display: block;
						color: $text-black;
						line-height: 22px;

						&:hover {
							color: $cccs-blue;
						}
					}

					// &:first-child {
					// 	margin-bottom: 10px;
					// }

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			.lvl2-name {
				position: relative;
				display: block;
				color: #fff;

				span {
					padding-right: 22px;
					display: block;
				}

				// &.clicked {
				// 	// text-decoration: underline;

				// 	&:after {
				// 		content: '';
				// 		display: block;
				// 		position: absolute;
				// 		top: 50%;
				// 		right: -8px;
				// 		border-top: 8px solid transparent;
				// 		border-bottom: 8px solid transparent;
				// 		border-left: 8px solid $text-black;
				// 		opacity: 1;
				// 		transform: translate(0, -50%);
				// 		z-index: 99000;
				// 		// transform: translateX(-50%);
				// 		// @include transition(opacity .35s ease-in-out);
				// 	}
				// }
			}

			.icon-circle-down {
				position: absolute;
				display: inline-block !important;
				right: 0;
				top: 50%;
				transform: translate(0, -50%);
			}

			&__row {
				padding: 0 20px 20px;

				.nav-megamenu__listing {
					display: none;
				}

				&.clicked {
					.nav-megamenu__listing {
						display: block;
						-webkit-animation: slide-down .35s ease-out;
						-moz-animation: slide-down .35s ease-out;
					}
				}
			}

			&__listing {
				> li {
					display: list-item;
					width: 100%;
				}
			}
		}

		&__right {
			float: right;
			padding: 17px 0;
			position: absolute;
			right: 0;
			bottom: 0;

			// .nav__print {
			// 	display: inline-block;
			// 	position: relative;
			// 	cursor: pointer;
			// 	padding: 5px 8px;
			// 	font-size: calc-em(18px);
			// }

			.nav__bell {
				display: inline-block;
				position: relative;
				// margin-right: 22px;
				@include transition(all .35s ease-in-out);
				padding: 5px 8px;

				.bell-notification {
					position: relative;
					@include transition(all .35s ease-in-out);

					&__count {
						position: absolute;
						top: 0;
						right: -4px;
						height: 12px;
						width: 12px;
						overflow: hidden;
						background: $cccs-yellow;
						color: #000;
						text-align: center;
						border-radius: 50%;
						font-size: calc-em(10px);
						border: none;
					}

					> i {
						font-size: calc-em(18px);
					}

					&:hover {
						@include transition(all .35s ease-in-out);
						cursor: pointer;
					}
				}
			}

			.nav__search {
				display: inline-block;
				padding: 5px 8px;

				&:hover {
					cursor: pointer;
				}
			}

			ul {
				display: inline-block;
			}

			li {
				display: inline-block;
				margin-right: 10px;

				&:last-child {
					margin: 0;
				}
			}

			a {
				display: block;
				text-decoration: none;

				i {
					font-size: calc-em(14px);
					@include transition(all .35s ease-in-out);
				}

				&:hover {
					i {
						@include transition(all .35s ease-in-out);
						font-size: calc-em(16px);
					}
				}
			}

			.btn-round {
				width: 28px;
				height: 28px;
				color: $cccs-blue;
				border-radius: 50%;
				border: 1.5px solid $cccs-blue;
				padding: 3px;
				outline: 0;
				text-align: center;
			}
		}

		/*************** SCROLLBAR BASE CSS ***************/

		.scroll-wrapper {
			overflow: hidden !important;
			padding: 0 !important;
			position: relative;
		}

		.scroll-wrapper > .scroll-content {
			border: none !important;
			box-sizing: content-box !important;
			height: auto;
			left: 0;
			margin: 0;
			max-height: none;
			max-width: none !important;
			overflow: scroll !important;
			padding: 0;
			position: relative !important;
			top: 0 !important;
			width: auto !important;
		}

		.scroll-wrapper > .scroll-content::-webkit-scrollbar {
			height: 0;
			width: 0;
		}

		.scroll-element {
			display: none;
		}

		.scroll-element, .scroll-element div {
			box-sizing: content-box;
		}

		.scroll-element.scroll-x.scroll-scrollx_visible,
		.scroll-element.scroll-y.scroll-scrolly_visible {
			display: block;
		}

		.scroll-element .scroll-bar,
		.scroll-element .scroll-arrow {
			cursor: default;
		}

		.scroll-textarea {
			border: 1px solid #cccccc;
			border-top-color: #999999;
		}

		.scroll-textarea > .scroll-content {
			overflow: hidden !important;
		}

		.scroll-textarea > .scroll-content > textarea {
			border: none !important;
			box-sizing: border-box;
			height: 100% !important;
			margin: 0;
			max-height: none !important;
			max-width: none !important;
			overflow: scroll !important;
			outline: none;
			padding: 2px;
			position: relative !important;
			top: 0;
			width: 100% !important;
		}

		.scroll-textarea > .scroll-content > textarea::-webkit-scrollbar {
			height: 0;
			width: 0;
		}

		/*************** SIMPLE INNER SCROLLBAR ***************/

		.scrollbar-inner > .scroll-element,
		.scrollbar-inner > .scroll-element div {
			border: none;
			margin: 0;
			padding: 0;
			position: absolute;
			z-index: 980;
		}

		.scrollbar-inner > .scroll-element div {
			display: block;
			height: 100%;
			left: 0;
			top: 0;
			width: 100%;
		}

		.scrollbar-inner > .scroll-element.scroll-x {
			display: none;
		}

		.scrollbar-inner > .scroll-element.scroll-y {
			height: 100%;
			right: 2px;
			top: 0;
			width: 8px;
		}

		.scrollbar-inner > .scroll-element .scroll-element_outer {
			overflow: hidden;
		}

		.scrollbar-inner > .scroll-element .scroll-element_outer,
		.scrollbar-inner > .scroll-element .scroll-element_track,
		.scrollbar-inner > .scroll-element .scroll-bar {
			-webkit-border-radius: 8px;
			-moz-border-radius: 8px;
			border-radius: 8px;
		}

		.scrollbar-inner > .scroll-element .scroll-element_track,
		.scrollbar-inner > .scroll-element .scroll-bar {
		    // -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
		    // filter: alpha(opacity = 40);
		    // opacity: 0.4;
		}

		.scrollbar-inner > .scroll-element .scroll-element_track {
			background-color: #e0e0e0;
		}

		.scrollbar-inner > .scroll-element .scroll-bar {
			background-color: #919191;
		}

		.scrollbar-inner > .scroll-element:hover .scroll-bar {
			background-color: #919191;
		}

		.scrollbar-inner > .scroll-element.scroll-draggable .scroll-bar {
			background-color: #919191;
		}

		/* update scrollbar offset if both scrolls are visible */

		// .scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
		//     left: -12px;
		// }

		.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
			top: -12px;
		}

		// .scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
		//     left: -12px;
		// }

		.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
			top: -12px;
		}

	}
}

@media only screen and (min-width: $bpMdDesktop) {
	.nav {

		> .container {
			padding: 0 20px;
		}

		&__lvl1 {
			margin: 0 -20px !important;
			float: right;
			padding-right: 74px;
			// padding-right: 80px;

			> li {
				// padding: 0 8px;
				padding: 0 12px;
				width: unset;

				.is-ie & {
					width: auto;
				}

				// &.height-overshot {
				// 	padding: 0 12px;
				// 	width: calc(100% / 7.2);

				// 	.scroll-wrapper {
				// 		top: 76px !important;
				// 	}
				// }

				// &.height-twoline {
				// 	padding: 0 12px;
				// 	width: calc(100% / 7.2);

				// 	// .scroll-wrapper {
				// 	// 	top: 76px !important;
				// 	// }
				// }

				// &.height-threeline {
				// 	// padding: 0 12px;
				// 	// width: calc(100% / 7.2);

				// 	.scroll-wrapper {
				// 		top: 94px !important;
				// 	}
				// }
			}
		}

		.nav-megamenu {
			// top: 94px;
			top: 76px;
			// top: 58px;
			// margin-left: -180px;

			// .is-ie & {
			// 	top: 94px;
			// }
			.nav__lvl2 {
				> li {
					padding: 14px 38px 14px 221px;
					// padding: 14px 38px 14px 262px;

					&:before {
						width: 200px;
						// width: 252px;
					}
				}
			}
		}
	}
}

// @media only screen and (min-width: $bpLgDesktop) {
// 	.nav {
// 		.nav-megamenu {
// 			.nav__lvl2 {
// 				> li {
// 					padding: 14px 38px 14px 285px;

// 					&:before {
// 						width: 275px;
// 					}
// 				}
// 			}
// 		}
// 	}
// }


@-webkit-keyframes slide-down {
	0% { opacity: 0; -webkit-transform: translateY(-100%); }
	100% { opacity: 1; -webkit-transform: translateY(0); }
}
@-moz-keyframes slide-down {
	0% { opacity: 0; -moz-transform: translateY(-100%); }
	100% { opacity: 1; -moz-transform: translateY(0); }
}
