// public-register-item styles
.public-register-item {
	@extend %clearfix;
	margin-bottom: 38px;

	&__image {
		display: inline-block;
		width: 75px;
		height: 60px;
		margin-right: 15px;
		vertical-align: top;

		img {
			width: 100%;
			height: 100%;
		}
	}

	&__text {
		display: inline-block;
		width: calc(100% - 100px);

		p {
			margin: 0;
			line-height: 24px;
		}

		.title {
			margin-bottom: 5px;

			a {
				color: $cccs-blue;
				font-weight: 700;
				text-decoration: none;
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.public-register-item {
		&__image {
			margin-right: 35px;
		}

		&__text {
			width: calc(100% - 120px);

			.title {
				@include transition(all .35s ease-in-out);

				&:hover {
					@include transition(all .35s ease-in-out);
					text-decoration: underline;
				}
			}
		}
	}
}
