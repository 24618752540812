// publications-item styles
.publications-item {
	// width: 100%;
	float: left;
	height: auto;
	margin-bottom: 35px;
	// width: 50%;
	padding: 0 20px;
	width: (100% / 2);

	a {
		display: block;
		text-decoration: none;
	}

	&__image {
		overflow: hidden;
		cursor: pointer;
		@include transition(all .35s ease-in-out);

		// img {
		// 	@include image-cover;
		// 	height: 265px;
		// }

		@include aspect-ratio(595, 842, 100%);

		img {
			@include image-cover;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__text {
		p {
			margin: 0;
			word-wrap: break-word;
		}

		.title {
			margin-top: 20px;
			font-size: calc-em(18px);
			color: $cccs-blue;
			font-weight: 600;
		}

		.text {
			margin-top: 16px;
			// line-height: 24px;
			line-height: 20px;
			color: $text-black;
		}

		// .download {
		// 	background-color: $cccs-yellow;
		// 	color: $text-black;
		// 	text-decoration: none;
		// 	padding: 15px 15px 15px 11px;
		// 	font-weight: 600;
		// 	display: inline-block;
		// 	margin-top: 25px;

		// 	i {
		// 		padding-right: 10px;
		// 		font-size: calc-em(17px);

		// 		&:before {
		// 			font-weight: 600;
		// 		}
		// 	}

		// 	.size {
		// 		padding-left: 3px;

		// 		&:before {
		// 			content: "(";
		// 			position: relative;
		// 		}

		// 		&:after {
		// 			content: ")";
		// 			position: relative;
		// 		}
		// 	}
		// }
	}

	.download {
		text-decoration: none;
		color: $text-black;
		font-weight: 600;
		margin-top: 16px;

		span {
			// border-bottom: 1px solid transparent;
		}

		i {
			margin-right: 17px;
			color: $cccs-blue;
		}

		.size {
			&:before {
				content: "(";
				padding-left: 3px;
				position: relative;
				font-weight: 600;
			}

			&:after {
				content: ")";
				position: relative;
				font-weight: 600;
			}
		}
	}
}

@media only screen and (min-width: $bpTablet) {
	.publications-item {
		width: (100% / 3);
	}
}

@media only screen and (min-width: $bpDesktop) {
	.publications-item {
		width: (100% / 4);

		&__image {
			overflow: hidden;

			img {
				@include transition(all .35s ease-in-out);
				vertical-align:bottom;

				&:hover {
					cursor: pointer;
					@include transition(all .35s ease-in-out);
					transform: scale(1.1);
				}
			}
		}

		&__text {
			// .download {
			// 	@include transition(all .35s ease-in-out);

			// 	&:hover span {
			// 		@include transition(all .3s ease-in-out);
			// 		// box-shadow: 0 0 20px 3px rgba(100, 100, 100, 0.2);
			// 		// box-shadow: 0 0 6px 1px rgba(100, 100, 100, 0.2);
			// 		border-bottom: 1px solid rgba(100, 100, 100, 0.6);
			// 	}
			// }

			.title {
				@include transition(all .35s ease-in-out);

				&:hover {
					@include transition(all .35s ease-in-out);
					text-decoration: underline;
				}
			}
		}
	}
}
