// faq-search styles
.faq-search {
	position: relative;

	&-input {
		width: 100%;
		background: $light-grey;
		border: none;
		margin-right: 15px;
		text-shadow: $text-black;
		color: $text-black;
		padding: 15px 18px;
		font-family: $primaryfont;
	}

	&-submit {
		background: $cccs-yellow;
		border: none;
		padding: 15px 30px 15px 20px;
		font-weight: 600;
		color: $text-black;
		font-family: $primaryfont;
		position: relative;

		i {
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translate(0, -50%);
			font-size: calc-em(11px);
			font-weight: 600;
		}
	}

	&__filter {
		margin-top: 15px;

		.text {
			display: block;
			margin-bottom: 5px;
		}

		.radio {
			display: inline-block;
			margin-right: 10px;
		}

		.radio-button {
			display: block;
			position: relative;
			padding-left: 25px;
			margin-bottom: 15px;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;

			input {
				position: absolute;
			    opacity: 0;
			    cursor: pointer;

			    &:checked ~ .checkmark:after {
			    	display: block;
			    }

			    &:checked ~ .checkmark {
			    	background-color: transparent;
			    }
			}

			.checkmark {
				position: absolute;
			    top: 0;
			    left: 0;
			    height: 18px;
			    width: 18px;
			    background-color: transparent;
			    border: 1px solid $radio;
			    border-radius: 50%;

			    &:after {
			    	content: "";
		    	    position: absolute;
		    	    display: none;
			    	top: 3px;
		    		left: 3px;
		    		width: 10px;
		    		height: 10px;
		    		border-radius: 50%;
		    		background: $cccs-blue;
			    }
			}

			&:hover {
				input ~ .checkmark {
					border: 1px solid $cccs-blue;
				}
			}
		}
	}
}

@media only screen and (min-width: $bpTablet) {
	.faq-search {
		&-input {
			width: calc(100% - 120px);
		}

		&-submit {
			position: absolute;
			top: 0;
			right: 0;
			width: 110px;
			padding: 15px 40px 15px 18px;
		}

		&__filter {
			margin-top: 25px;

			.text {
				display: inline-block;
			}

			.radio {
				margin-left: 20px;

				&:last-child {
					margin-left: 30px;
				}
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.faq-search {
		&-submit {
			@include transition(all .35s ease-in-out);

			&:hover {
				cursor: pointer;
				@include transition(all .35s ease-in-out);
				box-shadow: 0 0 6px 1px rgba(100, 100, 100, 0.2);
			}
		}
	}
}
