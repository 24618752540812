//Media query breakpoints
$bpMobile: 320px;
$bpMdMobile: 575px;
$bpLgMobile: 640px;
$bpTablet: 768px;
$bpDesktop: 1024px;
$bpMdDesktop: 1280px;
$bpLgDesktop: 1440px;

//font settings
$base-fontsize: 16px;
$primaryfont: 'Source Sans Pro', sans-serif;

//colors
$cccs-background: #f7f7f7;
$cccs-blue: #0066b3;
$cccs-yellow: #fdb909;

$text-black: #303030;
$text-light-black: #4b4b4b;
$text-white: #fdfdfd;
$text-grey: #4b4b4b;
$grey: #b6b6b6;
$light-grey: #ececec;
$light-grey-2: #cdcdcd;
$grey-box: #ededed;
$grey-box2: #ebebeb;
$black-box: #272727;
$gradient-box1: #1a1a1a;
$gradient-box2: #003e6c;

$box-outline: #e3e3e3;

$sidebar-grey: #e7e7e7;
$siderbar-line: #dcdcdc;

$nav-divider: #e2e2e2;
$divider: #d0d0d0;

$odd-table-row: #f0f0f0;
$radio: #969696;
$masthead: #f0f0f0;
