//helper classes

%list-unstyled {
	@include normalise-list;
}

%normalise-btn {
	@include normalise-button;
}

%clearfix {
	@include clearfix;
}

%visuallyHidden {
	@include visuallyHidden;
}

.visuallyHidden {
	@extend %visuallyHidden;
}

%background-cover {
	@include background-cover;
}

%image-cover {
	@include image-cover;
}

%vertical-divider {
	border-right: 1px solid #000000;
	width: 0;
}

.show {
	display: block !important;
}

.hide {
	display: none !important;
}

// .visuallyhidden-focusable:active,
// .visuallyhidden-focusable:focus {
// 	position: static;
// 	width: auto;
// 	height: auto;
// 	margin: 0;
// 	overflow: visible;
// 	clip: auto;
// }

// .text-hide {
// 	font: 0/0 a;
// 	color: transparent;
// 	text-shadow: none;
// 	background-color: transparent;
// 	border: 0;
// }
