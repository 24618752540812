// publications-widget styles
.publications {

	&__list {
		@extend %clearfix;
		@extend %list-unstyled;

		margin: 0 -20px;
	}
}

@media only screen and (min-width: $bpDesktop) {
	.publications {
		padding: 0 0 0 55px;
	}
}
