// help-widget-tabs styles
.help-widget-tabs {
	border: 0;
	margin-bottom: 10px;
	overflow: hidden;

	&__scroll {
		overflow-x: scroll;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: none;
		// display: inline-block;

		&::-webkit-scrollbar {
			width: 0px;  /* remove scrollbar space */
			background: transparent;  /* optional: just make scrollbar invisible */
		}
	}

	&__links {
		@extend %list-unstyled;
		@extend %clearfix;
		text-align: center;
		width: 100%;
		// min-width: 775px;
		// border-bottom: 1px solid $light-grey-2;

		a {
			@extend %clearfix;
			display: block;
			// display: inline-block;
			// width: 100%;
			width: 135px;
			border: 0;
			background: none;
			color: $text-black;
			// outline: 0;
			@include transition(all .35s ease-in-out);
			text-decoration: none;
			// font-size: calc-em(18px);
			letter-spacing: 0;
			padding-bottom: 20px;
			position: relative;
			padding-top: 10px;

			// &:after {
			// 	content: "";
			// 	position: absolute;
			// 	border-bottom: 3px solid $cccs-blue;
			// 	width: 50%;
			// 	left: 0;
			// 	transform: translate(50%, 0);
			// }

			p {
				margin: 0;
				// word-break: break-all;
				word-break: break-word;
				font-size: calc-em(14px);
			}

			img {
				width: 49px;
				height: 49px;
				margin-bottom: 14px;
			}
		}

		li {
			display: inline-block;
			padding: 0 15px;
			vertical-align: top;

			&.active {
				a {
					@include transition(all .35s ease-in-out);
					color: $cccs-blue;
					border-bottom: 4px solid $cccs-yellow;

					&:after {
						@include transition(all .35s ease-in-out);
						content: "";
						position: absolute;
						width: 50%;
						left: 0;
						transform: translate(50%, 0);
						margin-top: 10px;
					}

					p {
						font-weight: 600;
					}
				}

				&:hover {
					a {
						img {
							@include transition(all .35s ease-in-out);
							transform: scale(1);
						}
					}
				}
			}
		}
	}
}

// @media only screen and (min-width: $bpTablet) {
// 	.help-widget-tabs {
// 		&__links {
// 			min-width: unset;

// 			li {
// 				padding-bottom: 20px;

// 				a {
// 					padding-bottom: 10px;
// 				}
// 			}
// 		}
// 	}
// }

@media only screen and (min-width: $bpDesktop) {
	.help-widget-tabs {
		margin-bottom: 35px;

		&__scroll {
			overflow: unset;

			.is-ie & {
				overflow: visible;
			}
		}

		&__links {
			min-width: unset;
			border: none;
			padding-bottom: 8px;

			li {
				display: inline-block;
				padding: 0 15px;
				width: calc(99% / 4);

				a {
					img {
						@include transition(all .35s ease-in-out);
					}
				}

				&.active {
					a {
						font-weight: normal;
						text-shadow: -0.07ex 0 $cccs-blue, 0.07ex 0 $cccs-blue;
						border: none;

						&:after {
							border-bottom: 3px solid $cccs-blue;
						}

						p {
							font-weight: normal;
						}
					}
				}

				&:hover {
					a {
						img {
							@include transition(all .35s ease-in-out);
							transform: scale(1.1);
						}

						&:after {
							@include transition(all .35s ease-in-out);
							content: "";
							position: absolute;
							width: 50%;
							left: 0;
							transform: translate(50%, 0);
							margin-top: 10px;
						}
					}
				}

				// &:hover {
				// 	a {
				// 		&:after {
				// 			border-bottom: 3px solid $cccs-blue;
				// 		}
				// 	}
				// }
			}

			a {
				width: 100%;
				padding-bottom: 12px;
				margin-top: 10px;

				p {
					font-size: calc-em(18px);
					line-height: 22px;
				}
			}
		}
	}
}

@media only screen and (min-width: 1440px) {
	.help-widget-tabs {
		&__links {
			li {
				padding: 0 50px;
			}

			a {
				img {
					width: 79px;
					height: 79px;
					margin-bottom: 30px;
				}
			}
		}
	}
}
