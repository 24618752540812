// related-pages-item styles
.related-pages-item {
	@extend %clearfix;
	display: inline-block;
	width: 50%;

	&__text {
		padding-bottom: 30px;
	}

	.title {
		color: $cccs-blue;
		font-size: calc-em(18px);
		font-weight: 700;
		margin: 0;
		margin-bottom: 15px;
	}

	.desc {
		line-height: 24px;
		margin: 0;
	}

	.btn {
		font-weight: 700;
		// position: absolute;
		top: 0;
		left: 0;

		i {
			position: absolute;
			top: 50%;
			right: 15px;
			transform: translate(0, -50%);
			font-size: calc-em(12px);
		}
	}
}

@media print {
	.related-pages-item {
		width: 100%;
		margin-bottom: 50px;

		&__text {
			padding-bottom: 16px;
		}

		.btn {
			padding: 0 40px 0 0;
		}
	}
}
