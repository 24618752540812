// our-people-widget styles
.our-people {
	padding-top: 30px;
	padding-bottom: 65px;
	border-bottom: 1px solid $divider;

	&__intro {
		text-align: center;

		h2 {
			margin: 0;
			margin-bottom: 15px;
		}

		p {
			margin: 0;
			margin-bottom: 45px;
			font-size: calc-em(18px);
		}
	}

	&__testimontial {
		i {
			text-align: center;
			font-size: 4.5em;
			display: block;
		}
	}
}
