// public-register-widget styles
.public-register {

}

@media only screen and (min-width: $bpDesktop) {
	.public-register {
		padding: 0 0 0 55px;

		.social-sharing-widget {
			padding-left: 0;
		}
	}
}
