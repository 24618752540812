// know-people-widget styles
.know-people {
	margin-top: 60px;
	margin-bottom: 65px;

	h4 {
		margin: 0;
		margin-bottom: 35px;
	}
}
