// social-sharing-widget styles
.social-sharing-widget {
	position: relative;
	@extend %clearfix;

	&__left{
		// float: left;
		line-height: 34px;
		vertical-align: middle;
		margin-bottom: 30px;

		p {
			margin: 0;
			letter-spacing: 0;
		}
	}

	&__right {
		// float: left;
		vertical-align: middle;
		@extend %clearfix;

		.share-page {
			padding-right: 15px;
			letter-spacing: 0;
			position: relative;
			top: 8px;
			// transform: translate(0%, 50%);
			// width: 100%;
			// max-width: 400px;
			text-align: left;
			float: left;
		}

		&--medias {
			display: inline-block;
			// padding-left: 120px;
			float: left;
		}

		ul {
			@extend %list-unstyled;
			display: inline-block;
			float: left;
		}

		li {
			display: inline-block;
			margin-right: 10px;

			&:last-child {
				margin: 0;
			}

			a {
				text-decoration: none;
				display: block;
			}
		}

		.btn-round {
			width: 34px;
			height: 34px;
			color: $cccs-blue;
			border-radius: 50%;
			border: 1.5px solid $cccs-blue;
			padding: 7px;
			outline: 0;
			text-align: center;
			margin: 0 2px 5px;
		}

		.links {
			.at-share-btn-elements .at-share-btn {
				border: 1.5px solid $cccs-blue !important;
				border-radius: 50% !important;
				margin-right: 10px;

				&:hover, &:focus {
					transform: none !important;
				}
			}

			&.additional-links {
				// transform: translate(0, -40%);

				i {
					&:before {
						position: relative;
						top: 1px;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: $bpMdMobile) {
	.social-sharing-widget {
		&__left{
			float: left;
		}

		&__right {
			float: right;

			&--medias {
				padding: 0;
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.social-sharing-widget {
		padding-left: 55px;

		&__left {
			margin: 0;
		}
	}
}
