// faq-side-nav styles
.faq-side-nav {
	// margin-bottom: 85px;
	display: none;

	ul {
		@extend %list-unstyled;
	}

	li {
		background: $sidebar-grey;
		position: relative;
		margin-bottom: 3px;
		@include transition(all .35s ease-in-out);

		a {
			display: inline-block;
			width: 100%;
			padding: 15px;
			text-decoration: none;
			color: #000;
			position: relative;
			letter-spacing: 0;
			color: $text-black;
		}

		&:hover {
			@include transition(all .35s ease-in-out);
			background: $cccs-blue;

			a {
				color: #fff;
			}
		}

		&.active {
			background-color: $cccs-blue;

			a {
				color: #fff;
			}
		}

		&.expanded {
			> a {
				background: $cccs-blue;
				color: #fff;
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.faq-side-nav {
		display: block;
		margin-bottom: 85px;
	}
}
