// related-listing-widget styles
.related-listing {
	margin-top: 32px;
	margin-bottom: 75px;

	.title {
		font-size: calc-em(19px);
		font-weight: 600;
		margin: 0;
		margin-bottom: 25px;
		color: $text-black;
	}

	.rte {
		padding: 0;

		p {
			margin: 0;
			margin-bottom: 25px;
			color: $text-light-black;
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.related-listing {
		margin-top: 48px;

		.row {
			margin: 0 -25px;
		}
	}
}
