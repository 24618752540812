// listing-table-widget styles
.listing-table {

	.title-padding {
		margin-bottom: 18px;
	}

	&__table {
		border-collapse: collapse;
		margin-bottom: 30px;
		width: 100%;
	}

	tr {
		text-align: left;

		td {
			text-align: left;
		}

		.date {
			text-align: left;
			padding: 20px;
		}

		.name {
			padding: 20px 26px;
			font-weight: 600;
			@include transition(all .35s ease-in-out);
			text-align: left;

			a {
				@include transition(all .35s ease-in-out);
				text-decoration: none;
				color: $text-black;
				line-height: 24px;
				color: $cccs-blue;
			}

			> p {
				font-weight: 400;
			}
		}

		.topic {
			padding: 20px 10px 20px 20px;
			text-align: left;
		}

		&:nth-child(odd) {
			background-color: $odd-table-row;
		}

		&.listing-table__table--header {
			background-color: #fff;
		}

		.status {
			padding: 20px 10px 20px 20px;
			text-align: left;

			a {
				@include transition(all .35s ease-in-out);
				color: $cccs-blue;
				line-height: 22px;
				text-decoration: none;
			}
		}
	}

	&--filter {
		display: inline-flex;
		align-items: center;
		width: 100%;
		margin-bottom: 35px;
		.listing-search-field {
			position: relative;
			border: 1px solid #d0d0d0;

			&__input {
				padding: 15px 40px 15px 20px;
				border: 0;
				outline: 0;
				width: 300px;
				padding-left: 12px;
				text-shadow: $text-black;
				font-family: $primaryfont;
			}
			.btn-search {
				position: absolute;
				right: 2px;
				top: 0;
				z-index: 1;
				padding: 17px 7px;
				height: 100%;
				width: 36px;
				border: 0;
				background: none;
				text-align: center;
				font-size: calc-em(16px);
				color: $cccs-blue;
		
				&:focus {
					outline: 0;
				}
			}
		}
		.year-filter {
			margin: 0 0 0 auto;

			.year-filter__selected {
				width: 200px;
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.listing-table {
		padding: 0 0 0 55px;

		.name, .status {
			a {
				&:hover {
					cursor: pointer;
					text-decoration: underline;
				}
			}
		}
	}
}

@media only screen and (max-width: $bpTablet - 1) {
	.listing-table {
		&--filter {
			display: block;

			.listing-search-field {
				display: block;
				margin-bottom: 10px;

				.btn-search {
					right: 10px;
				}

				&__input {
					padding: 15px 0 15px 20px;
					width: 85%;
				}
			}
			.year-filter {
				display: block;
				.year-filter__selected {
					width: 100%;
				}
			}
		}
		.listing-table__table--header {
			.name {
				padding: 20px 15px;
			}
			.date {
				padding: 20px 15px;
				width: 150px !important;
			}
		}

		.listing-table__table--content {
			.name {
				padding: 20px 15px;
			}
			.date {
				padding: 20px 15px;
			}

		}
	}
}
