// landing-widget styles
.landing {

	&-widget {
		margin-top: 32px;
		margin-bottom: 75px;

		.rte {
			padding: 0;

			h1, h2, h3, h4, h5, h6 {
				margin: 0;
				margin-bottom: 38px;
				font-size: calc-em(19px);
			}
		}
	}

	&-item {
		padding: 0 10px;

		a {
			display: block;
			margin-bottom: 23px;

			.landing-item__image {
				@include aspect-ratio(310, 190, 100%);

				img {
					@include image-cover;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}

		&__text {
			margin-bottom: 25px;

			p {
				margin: 0;
				line-height: 24px;
				color: $text-light-black;
			}

			.related-title {
				font-size: calc-em(19px);
				font-weight: 600;
				margin-bottom: 18px;
				color: $text-black;
			}
		}

		a.btn {
			font-weight: 600;
			font-size: calc-em(18px);
			display: inline-block;

			i {
				position: absolute;
				right: 15px;
				top: 50%;
				font-size: calc-em(10px);
				transform: translateY(-50%);
				font-weight: 900;
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.landing {

		&-widget {
			margin-top: 48px;

			.row {
				margin: 0 -25px;
			}
		}

		&-item {
			padding: 0 25px;

			a {
				overflow: hidden;

				img {
					@include transition(all .35s ease-in-out);

					&:hover {
						@include transition(all .35s ease-in-out);
						transform: scale(1.1);
					}
				}
			}
		}
	}
}
