// public-updates-widget styles
.public-updates {
	position: relative;

	h4 {
		margin: 0;
		margin-bottom: 30px;
	}

	&__table {
		border-collapse: collapse;
		margin-bottom: 55px;
	}

	tr {
		th {
			margin-bottom: 22px;
			text-align: left;
		}

		img {
			width: 40px;
			height: 25px;
		}

		&.public-updates__table--item {
			&:nth-child(even) {
				background-color: $odd-table-row;
			}
		}

		.date, .topic, .type, .category {
			padding: 20px;
		}

		.status {
			padding: 20px;
			line-height: 22px;

			a {
				@include transition(all .35s ease-in-out);
				text-decoration: none;
			}
		}

		a {
			color: $cccs-blue;
		}

		.title {
			line-height: 24px;
			font-weight: 600;
			padding: 20px 30px 20px 20px;

			a {
				@include transition(all .35s ease-in-out);
				text-decoration: none;
			}
		}
	}
}

@media only screen and (max-width: $bpDesktop - 1) {
	.public-updates {
		&__table {
			margin-top: 10px;

			&-row {
				padding: 16px 0;
				border-top: 1px solid $divider;

				> div {
					position: relative;
					margin-bottom: 25px;
					line-height: 24px;
					padding-left: 100px;
				}

				.heading {
					position: absolute;
					top: 0;
					left: 0;
					max-width: 100px;
					font-weight: 700;
				}

				img {
					width: 60px;
					height: 30px;
				}

				a {
					font-weight: 600;
					color: $cccs-blue;
					text-decoration: none;
				}

				.status {
					color: $cccs-blue;
				}
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.public-updates {
		padding: 0 0 0 55px;

		table {
			tbody {
				width: 100%;
				display: table;
			}
		}

		h4 {
			margin-bottom: 40px;
			display: inline-block;
		}

		.title, .status {
			a {
				&:hover {
					cursor: pointer;
					text-decoration: underline;
				}
			}
		}


	}
}
