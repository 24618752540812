// site-header styles
.site-header {
	margin: 0;

	&__masthead {
		background-color: $masthead;

		a {
			display: block;
			padding: 4px 0;
			text-decoration: none;
			line-height: 20px;
			color: $text-black;

			&:hover {
				color: $cccs-blue;
			}
		}

		img {
			height: 18px;
			padding-right: 5px;
		}

		span {
			vertical-align: top;
			font-size: calc-em(14px);
		}
	}

	&__top {

		&--left {
			@extend %clearfix;
			display: inline-block;
		}

		&--right {
			@extend %clearfix;
			// float: right;
			// vertical-align: bottom;
			// display: inline-block;
			display: block;
			margin: 0 -20px;
		}

		.site-logo {
			display: inline-block;
			padding: 10px 0;

			a {
				display: block;
			}

			img {
				width: 120px;
			}
		}
	}

	.m-search-bar {
		display: none;
	}

	.mobile-header {
		a {
			text-decoration: none;
		}

		.search-menu {
			position: absolute;
			right: 48px;
			top: 0;
			width: 69px;
			height: 69px;
			padding: 25px;
			color: $cccs-blue;
			outline: 0;

			.icon-search {
				font-size: calc-em(20px);
			}

			&:hover, &:focus {
				text-decoration: none;
			}
		}

		.alert-btn {
			display: block;
			position: absolute;
			top: 0;
			right: 50px;
			height: 69px;
			z-index: 1;
			width: 69px;
			padding: 25px;
			color: $cccs-blue;
			outline: 0;

			&:hover, &:focus {
				text-decoration: none;
			}

			.icon-bell {
				font-size: calc-em(20px);
				color: #000;
			}

			.bell-notification {
				position: relative;

				&__count {
					position: absolute;
					top: 0;
					right: -4px;
					height: 12px;
					width: 12px;
					overflow: hidden;
					background: $cccs-yellow;
					color: #000;
					text-align: center;
					border-radius: 50%;
					font-size: calc-em(10px);
					border: none;
				}
			}
		}

		.mobile-menu {
			position: absolute;
			width: 69px;
			height: 69px;
			top: 0;
			right: 0;
			border: 0;
			padding: 20px;
			background: none;
			outline: 0;

			.line {
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				height: 4px;
				width: 24px;
				background: $cccs-blue;
				font-weight: 600;
				border-radius: 5px;
				@include transition(transform .35s ease-in-out, width .35s ease-in-out);

				&:nth-child(2) {
					@include transform(translateY(-8px) scaleX(1));
				}

				&:nth-child(3),
				&:nth-child(4) {
					@include rotate(0deg);
				}

				&:nth-child(5) {
					@include transform(translateY(8px) scaleX(1));
				}
			}

			&.is-active {
				// background: $grey-light-darken;

				.line {
					&:nth-child(2),
					&:nth-child(5) {
						@include transform(translateY(0) scaleX(0));
					}

					&:nth-child(3) {
						@include rotate(45deg);
					}

					&:nth-child(4) {
						@include rotate(-45deg);
					}
				}
			}
		}
	}

	.notification-bar {
		display: none;
		position: relative;
		// top: 62px;
		left: 0;
		right: 0;
		background-color: $cccs-blue;
		z-index: 10;
		color: #fff;
		font-size: calc-em(14px);

		p {
			line-height: 20px;
		}

		a {
			color: #fff;
		}
	}

	.search-bar {
		display: none;
		position: relative;
		left: 0;
		right: 0;
		z-index: 10;
		color: #fff;
		font-size: calc-em(14px);

		#siteSearch {
			width: calc(100% - 180px);
			border: none;
			height: 48px;
			padding: 10px 0 10px 30px;

			&:focus {
				outline: none;
			}
		}

		.site-search--error-message {
			color: red;
			margin: 0;
			padding: 0 0 10px 30px;
			display: none;
		}

		.btn-search {
			position: absolute;
			left: 15px;
			top: 8px;
			z-index: 1;
			height: 33px;
			width: 36px;
			border: 0;
			padding: 7px;
			background: none;
			text-align: center;
			font-size: 1.2em;
			color: $grey;
		}

		.filter-select {
			display: inline-block;
			position: absolute;
			right: 20px;
			top: 8px;

			#searchWithinMobile, #searchWithinSite {
				padding: 6px;
				border: none;
				background: $light-grey;
				color: $text-black;
				font-size: calc-em(14px);
				font-family: $primaryfont;
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.site-header {
		position: relative;
		background-color: #fff;
		@include transition(all .35s ease-in-out);
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 100;

		&.is-sticky {
			@include transition(all .35s ease-in-out);
			box-shadow: 0 0 15px 3px rgba(100, 100, 100, 0.6);
			transform: translate(0, 0);
			// position: fixed;
			// animation: slide-down 0.7s;
			// top: 0;
			// left: 0;
			// width: 100%;
			// z-index: 99990;
			// background-color: #fff;
			// @include transition(all .35s ease-in-out);
			// transition: all 0.5s;
		}

		&__masthead {
			span {
				font-size: calc-em(16px);
			}
		}

		&__top {
			height: unset;
			padding-top: 16px;
			// padding-top: 21px;

			.site-logo {
				float: left;
				margin-top: 8px;

				img {
					width: 180px;
				}
			}

			&--right {
				display: inline-block;
				margin: 0;
				width: calc(100% - 220px);
				// float: right;
				position: absolute;
				bottom: 0;
			}
		}

		.m-search-bar {
			display: none !important;
		}
	}
}

@media only screen and (min-width: $bpLgDesktop) {
	.site-header {
		&__top {
			&--right {
				width: calc(100% - 200px);
			}
		}

		.search-bar {
			.filter-select {
				right: 0;
			}
		}
	}
}

@keyframes slide-down {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 0.9;
	}
}

@media only screen and (max-width: $bpDesktop - 1) {
	.site-header {
		@include transition(all .35s ease-in-out);
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 100;

		&.is-sticky {
			@include transition(all .35s ease-in-out);
			box-shadow: 0 0 15px 3px rgba(100, 100, 100, 0.6);
			transform: translate(0, 0);
		}

		// &.is-sticky {
		// 	position: fixed;
		// 	z-index: 99990;
		// 	background-color: #fff;
		// 	height: 63px;
		// 	width: 100%;
		// 	@include transition(all .35s ease-in-out);
		// }

		&__top {
			position: relative;
			background-color: #fff;
			z-index: 99990;
		}

		&__bottom {
			position: absolute;
			width: 100%;
			// height: calc(100vh - 200px);
			// overflow-y: scroll;
			// -webkit-overflow-scrolling:touch;
			// z-index: -1;

			&.clicked {
				// z-index: 1000;
				height: calc(100vh - 200px);
				overflow-y: scroll;
				-webkit-overflow-scrolling:touch;
			}
		}

		.m-search-bar {
			// display: none;
			// position: relative;
			// top: auto;
			// left: 0;
			// width: 100%;
			padding: 20px 0 0 0;
			background: #fff;
			// background: $cccs-blue;
			color: #fff;
			// transform-origin: top;
			// @include transform(scaleY(0));
			// @include transition(transform .25s ease-in-out);
			// @include transition(all .35s ease-in-out);
			z-index: 99999;
			// min-height: 150px;

			#mobileSearch {
				position: relative;
				width: 100%;
				border: 0;
				border-bottom: 1px solid $nav-divider;
				padding-right: 25px;
				background: none;
				outline: 0;
				@include transition(border .15s ease-in-out);
				color: $text-black;
				font-family: $primaryfont;

				&::-webkit-input-placeholder,
				&:-moz-placeholder,
				&::-moz-placeholder,
				&:-ms-input-placeholder {
				   color: #fff;
				}
			}

			.site-search--error-message {
				color: red;
				margin: 0;
				padding: 10px 0;
				display: none;
			}

			.btn-search {
				position: absolute;
				top: 0;
				right: 20px;
				border: 0;
				padding: 0;
				background: none;
				font-size: calc-em(18px);

				i {
					color: #fff;
				}
			}

			.filter-select {
				.dropdown-copy {
					width: 100%;
				}

				#searchWithinMobile, #searchWithinSite {
					font-family: $primaryfont;
					width: 100%;
					font-size: calc-em(13px);
				}
			}
		}

		.m-noti-bar {
			display: none;
			position: relative;
			left: 0;
			right: 0;
			margin: 0 -20px;
			background-color: $cccs-blue;
			color: #fff;
			z-index: 10;

			p {
				margin: 0;
				padding: 20px;
			}
		}
	}
}
