// case-registry-widget styles
.case-registry {
 	// background: linear-gradient(-90deg, $gradient-box1, $gradient-box2);
 	// background: linear-gradient(to bottom, #000000 0%,#003e6c 81%);
 	background: #1a1a1a;
 	background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,62,108,1) 100%, rgba(0,62,108,1) 100%); /* w3c */

 	&__intro {
 		text-align: center;
 		color: #fff;
 		padding: 35px 0 28px 0;
 		// padding: 70px 0 30px 0;

 		h2 {
 			font-size: calc-em(16px);
 			// font-weight: normal;
 			margin: 0;
 			margin-bottom: 15px;
 		}

 		p {
 			margin: 0;
 			line-height: 18px;
 			letter-spacing: 0;
 			font-size: calc-em(15px);
 		}
 	}

 	&__wrapper {
 		@extend %clearfix;
 		padding: 0 0 30px 0;
 		vertical-align: top;
 		margin: 0;
 		overflow: hidden;
 	}

 	&__scroll {
 		overflow-x: scroll;
		overflow-y: hidden;
		margin: 0 -20px;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: none;

		&::-webkit-scrollbar {
			width: 0px;  /* remove scrollbar space */
			background: transparent;  /* optional: just make scrollbar invisible */
		}
 	}

 	&__listing {
 		min-width: 880px;
		// text-align: center;
		 
		&.one-item {
			min-width: unset;
			text-align: center;
		}

		&.two-items {
			min-width: 365px;
		}

		&.three-items {
			min-width: 540px;
		}

		&.four-items {
			min-width: 710px;
		}
 	}

	&__item {
 		@extend %clearfix;
		// background: #fff;
		background: $cccs-background;
 		display: inline-block;
 		margin-left: 20px;
 		position: relative;
 		width: 150px;
 		// width: 160px;
 		height: 130px;
 		text-align: center;
 		vertical-align: top;

 		// &:last-child {
 		// 	margin: 0;
 		// }

		.item-card {
			display: block;
			text-decoration: none;
			color: $text-black;
			font-weight: 700;
		}
	}

 	.image-card {
 		&__image {
 			padding-top: 20px;

 			img {
 				height: 40px;
 			}
 		}

 		&__text {
 			span {
 				display: block;
 				padding: 10px 10px 28px;
 				font-weight: 600;
 				line-height: 20px;
 				font-size: calc-em(14px);
 			}
 		}
 	}
}

@media only screen and (min-width: 360px) {
	.case-registry {
		&__listing {

			&.two-items {
				min-width: unset;
				text-align: center;

				.case-registry__item {
					margin-left: 0;

					&:nth-child(2) {
						margin-left: 20px;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 540px) {
	.case-registry {
		&__listing {

			&.three-items {
				min-width: unset;
				text-align: center;

				.case-registry__item {
					&:first-child {
						margin-left: 0;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 710px) {
	.case-registry {
		&__listing {

			&.four-items {
				min-width: unset;
				text-align: center;

				.case-registry__item {
					&:first-child {
						margin-left: 0;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: $bpTablet) {
	.case-registry {
		&__listing {
			min-width: unset;
			text-align: center;

			&.five-items {
				min-width: 880px;
				text-align: unset;
			}
		}
	}
}

@media only screen and (min-width: 880px) {
	.case-registry {
		&__listing {
			min-width: unset;
			text-align: center;

			&.five-items {
				text-align: center;

				.case-registry__item {
					&:first-child {
						margin-left: 0;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.case-registry {
		&__wrapper {
			padding: 40px 0 90px 0;
			overflow: unset;
		}

		&__intro {
			padding: 65px 0 28px 0;

			h2 {
				font-size: calc-em(28px);
			}

			p {
				font-size: calc-em(18px);
				line-height: 25px;
			}
		}

		&__scroll {
			overflow: unset;
			margin: 0;

			.is-ie & {
				overflow: visible;
			}
		}

		// &__listing {
		// 	min-width: unset;
		// 	text-align: center;
		// }

		.image-card {
			&__image {
				padding-top: 30px;

				img {
					height: 60px;
					@include transition(all .35s ease-out);

					// &:hover {
					// 	@include transition(all .35s ease-in-out);
					// 	transform: scale(1.1);
					// }
				}
			}

			&__text {
				span {
					padding: 20px 40px 28px;
					font-size: calc-em(16px);
					font-weight: 700;
				}
			}
		}

		&__item {
			height: auto;
			width: calc(100%/5 - 20px);
			@include transition(all .35s ease-out);

	 		&:hover {
	 			@include transition(all .35s ease-out);
	 			box-shadow: 0 0 6px 6px rgba(100, 100, 100, 0.6);
	 			// box-shadow: 0 0 20px 3px rgba(255, 255, 255, 0.2);

	 			.image-card__image {
	 				img {
	 					@include transition(all .35s ease-in-out);
	 					transform: scale(1.1);
	 				}
	 			}

	 			.image-card__text {
					span {
						@include transition(all .35s ease-in-out);
						text-decoration: underline;
					}
	 			}
	 		}

	 		&:first-child {
	 			margin: 0;
	 		}
		}
	}
}
