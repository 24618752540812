// our-people-item styles
.our-people-testi {
	margin-top: 40px;

	// &__topslider {
	// 	margin-bottom: 25px;
	// }

	&__quotes {
		text-align: center;
		// font-size: calc-em(19px);
		min-height: 120px;

		.text {
			margin: 0;
			margin-bottom: 30px;
		}

		.author {
			margin: 0;
			font-style: italic;

			&:before {
				content: "-";
				position: relative;
				padding-right: 3px;
			}
		}
	}

	&__people {
		overflow: hidden;
		text-align: center;
		padding-top: 20px;

		ul {
			@extend %list-unstyled;
			min-width: 100px;
			position: relative;
			display: block;
		}

		li {
			display: inline-block;
			padding-right: 5px;
		}
	}

	&__scroll {
		overflow-x: scroll;
		overflow-y: hidden;
		// margin: 0 -20px;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: none;

		&::-webkit-scrollbar {
			width: 0px;  /* remove scrollbar space */
			background: transparent;  /* optional: just make scrollbar invisible */
		}
	}

	&__content {
		display: none;
		opacity: 0;
	}

	// &__btmslider {
	// 	position: relative;
	// 	left: 0;
	// 	right: 0;

	// 	.slick-slide {
	// 		width: 80px;
	// 		display: inline-block !important;
	// 		margin: 0 6px;
	// 	}
	// }

	&__image {
		position: relative;
		height: 100%;
		width: 80px;

		img {
			@include image-cover;
			border-radius: 50%;
			width: 100%;
			height: 100%;
		}
	}
}

@media only screen and (min-width: $bpTablet) {
	.our-people-testi {
		// &__btmslider {
		// 	.slick-slide {
		// 		width: 80px;
		// 		display: inline-block !important;
		// 		margin: 0 10px;
		// 	}
		// }
	}
}

@media only screen and (min-width: 850px) {
	.our-people-testi {
		// &__btmslider {
		// 	text-align: center;
		// 	width: 100%;
		// 	margin: auto;

		// 	.slick-slide {
		// 		width: unset !important;
		// 		float: inherit;
		// 		display: inline-block !important;
		// 		vertical-align: middle;
		// 		margin: 0;

		// 		.is-ie & {
		// 			width: auto !important;
		// 		}
		// 	}
		// }

		&__image {
			padding: 0px 10px;

			img {
				width: 75px;
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.our-people-testi {
		// &__topslider {
		// 	margin-bottom: 45px;
		// }

		&__scroll {
			overflow: unset;
		}

		&__quotes {
			font-size: calc-em(19px);
		}

		&__image {
			padding: 8px 10px;
			overflow: hidden;
			width: 100%;
			height: 95px;
			// @include transition(all .35s ease-in-out);

			img {
				width: 75px;
				@include transition(all .35s ease-in-out);

				&:hover {
					// cursor: pointer;
					@include transition(all .35s ease-in-out);
					// box-shadow: 0 0 6px 1px rgba(100, 100, 100, 0.4);
					transform: scale(1.1);
				}
			}

			&:hover {
				// cursor: pointer;

				img {
					// @include transition(all .35s ease-out);
					// transform: scale(1.1);
					// box-shadow: 0 0 20px 3px rgba(100, 100, 100, 0.2);
					// box-shadow: 0 0 6px 1px rgba(100, 100, 100, 0.4);
				}
			}
		}
	}

}
