// accordion styles
.accordion {
	margin-bottom: 7px;

	&:hover {
		cursor: pointer;
	}

	&__controller {
		position: relative;
		background-color: $cccs-blue;

		p {
			width: 100%;
			margin: 0;
			line-height: 18px;
			padding: 15px 25px 15px 15px;
			border-right: 2px solid #fff;
			color: #fff;
			font-weight: 500;
		}

		i {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translate(0, -50%);
			font-size: calc-em(10px);
			color: #fff;
			padding: 20px 17px;
			@include transition(all .35s ease-in-out);
		}
	}

	&__content {
		display: none;
		padding: 23px;
	}

	&.open {
		.accordion__controller {
			i {
				transform: translate(0, -50%) rotate(180deg);
				@include transition(all .35s ease-in-out);
			}
		}
	}

	&:last-child {
		margin-bottom: 45px;
	}
}
