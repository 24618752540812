// public-updates-search styles
.public-updates-search {
	position: relative;

	#registerSearch {
		height: 33px;
		width: 100%;
		border: 1px solid $grey;
		padding-left: 12px;
		text-shadow: $text-black;
		font-family: $primaryfont;
		color: $text-black;

		&:focus {
			outline: 0;
		}
	}

	.btn-search {
		position: absolute;
		right: 2px;
		top: 0;
		z-index: 1;
		height: 33px;
		width: 36px;
		border: 0;
		padding: 7px;
		background: none;
		text-align: center;
		font-size: calc-em(16px);
		color: $cccs-blue;

		&:focus {
			outline: 0;
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.public-updates-search {
		display: inline-block;
		position: absolute;
		right: 0;
		top: 0;

		#registerSearch {
			width: 230px;
		}
	}
}
