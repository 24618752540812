// page-not-found styles
.error {
	padding: 96px 20px 240px;
	text-align: center;

	h1 {
		color: $text-black;
		margin: 0 0 48px;
	}

	p {
		font-size: calc-em(20px);
		line-height: 28px;
		color: $text-black;
		max-width: 650px;
		margin: 0 auto;
	}
}

@media only screen and (max-width: $bpTablet - 1){
	.error {
		padding: 64px 20px;

		p {
			font-size: calc-em(18px);
			line-height: 26px;
		}
	}
}
