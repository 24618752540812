//Import helpers
@import './_variables';
@import './_mixins';
@import './_helpers';
@import './_icons';
@import './_grids';

// Normalize Styles
@import 'node_modules/normalize.css/normalize';

//Plugin Styles
@import 'node_modules/slick-carousel/slick/slick.scss';
@import './jquery.fancybox';

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: $primaryfont;
  color: $text-black;
  font-size: $base-fontsize;
  letter-spacing: 0;
  background: #fff;
}

h1, h2, h3, h4, h5, h6 { font-weight: bold; }
h1 { font-size: calc-em(36px); }
h2 { font-size: calc-em(32px); }
h3 { font-size: calc-em(28px); }
h4 { font-size: calc-em(24px); }
h5 { font-size: calc-em(20px); }
h6 { font-size: calc-em(16px); }

%container {
  position: relative;
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 20px;
}

.container {
  @extend %container;
  @extend %clearfix;
  width: auto;

  &--narrow {
    max-width: 768px;
  }
}

// Hide the clear button in IE
input::-ms-clear {
  display: none;
}

img { max-width: 100%; }

.mainContent {
  margin-top: 92px;
  position: relative;

  &.bg-overlay {
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #000000;
      z-index: 80;
      opacity: 0.5
    }
  }
}

.standard-content {
  margin-bottom: 36px;
}

.content-body {
  border-top: 1px solid $box-outline;
  padding-top: 25px;
}

.mobileOnly, .upToTablet {
  display: block !important;
}

.mobileInlineBlock {
  display: inline-block !important;
}

.tabletOnwards, .desktopOnly, .desktopInlineBlock {
  display: none !important;
}

article {
  @extend %clearfix;
}

// .bg-overlay {
//   // display: block;
//   top: 0;
//   left: 0;
//   position: relative;
//   // position: fixed;
//   width: 100%;
//   height: 100%;
//   background: #000000;
//   opacity: 0.5;
//   z-index: 100;
//   transition: opacity .25s;
// }

.search-website {
  .filter-select {
    margin-top: 10px;
    position: relative;

    .dropdown-copy {
      position: relative;
      display: block;
      width: 230px;

      > button {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        line-height: inherit;
        letter-spacing: 0;
        text-align: left;
        position: relative;
        width: 100%;
        height: 33px;
        border: none;
        padding: 6px 35px 6px 10px;
        font-size: calc-em(12px);
        color: $text-black;
        // border: 1px solid $border4;

        &:after {
          transform: rotate(45deg);
          position: absolute;
          top: -4px;
          right: 10px;
          bottom: 0;
          display: block;
          width: 6px;
          height: 6px;
          margin: auto;
          content: "";
          border-right: 1px solid $text-black;
          border-bottom: 1px solid $text-black;
        }

        &:focus {
          border: none;
        }
      }
    }

    select {
      position: absolute;
      display: block;
      top: 0;
      opacity: 0;
      height: 33px;
      margin: 0 0 -40px;
      width: 230px;
    }
  }
}

.title-padding {
  margin: 0;
  margin-bottom: 35px;
  color: $cccs-blue;
}

.btn-yellow {
  position: relative;
  color: $text-black;
  text-decoration: none;
  padding: 15px 40px 15px 18px;
  display: inline-block;
  background-color: $cccs-yellow;
  @include transition(all .35s ease-in-out);

  &:hover {
    @include transition(all .35s ease-in-out);
    box-shadow: 0 0 6px 1px rgba(100, 100, 100, 0.2);
  }
}

.sitemap-page {
  h1 {
    margin: 0;
    margin-bottom: 35px;
    color: $cccs-blue;
  }

  ul {
    li {
      color: $text-black;
      line-height: 26px;
    }

    a {
      color: $text-black;
      text-decoration: none;
      line-height: 26px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media only screen and (min-width: $bpTablet) {
  .upToTablet {
    display: none !important;
  }
  .tabletOnwards {
    display: block !important;
  }
}

@media only screen and (min-width: $bpDesktop) {
  .mobileOnly, .mobileInlineBlock {
    display: none !important;
  }
  .desktopOnly {
    display: block !important;
  }
  .desktopInlineBlock {
    display: inline-block !important;
  }

  .standard-page {
    .standard-content {
      float: right;
      margin-bottom: 85px;
    }
  }

  .content-body {
    padding-top: 45px;
    // border: none;
  }

  .search-website {
    .filter-select {
      margin: 0;
    }
  }

  .mainContent {
    margin-top: 140px;
    // margin-top: 187px;
  }
}

@media only print {
  body {
    width: 100%;
  }

  .site-header, .breadcrumb, .side-nav, .social-media-widget, .report-download, .site-footer, .social-sharing-widget {
    display: none;
  }

  .rte table{
    max-width: 100%;
  }

  .mainContent {
    margin: 0;
    background-color: red;
  }

  .banner-carousel  {
    width: 100%;
    height: 100% !important;

    .carousel-btn {
      display: none;
    }

    %__item {
      height: 100%;
    }

    // &__image {
    //   display: none;
    // }

    &__text {
      padding: 0;
      margin: 0 -40px;
    }

    .slick-track {
        -webkit-transform:translateZ(0)!important;
        transform:translateZ(0)!important;
        width:100%!important;
        position: relative;
        left: 0;
    }

    .slick-slide{
        float:none!important;
        width:100%;
        position: unset !important;
        opacity: 1 !important;

        &.slick-cloned {
          display: none !important;
        }
    }

    .slick-dots {
        display:none!important;
    }
  }

  .related-pages  {
    width: 100%;
    height: 100% !important;

    .carousel-topBtn {
      display: none !important;
    }

    %__item {
      height: 100%;
    }

    &__text {
      padding: 0;
      margin: 0 -40px;
      width: calc(100% - 1000px);
    }

    .slick-track {
        -webkit-transform:translateZ(0)!important;
        transform:translateZ(0)!important;
        width:100%!important;
    }

    .slick-slide{
        float:none!important;
        width:100% !important;
        position: unset !important;
        opacity: 1 !important;

        &.slick-cloned {
          display: none !important;
        }
    }
  }

  .help-widget-tabs {
    &__links {
      margin-left: -40px;
    }
  }

  .case-registry, .latest-resources {
    &__scroll {
      overflow: unset;
      margin: 0;
    }

    &__listing {
      min-width: unset !important;
    }

    &__item {
      margin-bottom: 20px;
    }
  }

  .publications-item {
    height: 100% !important;

    &__text {
      min-height: 150px;

      .title {
        height: 100% !important;
      }
    }
  }

  .public-updates {
    &__table {
      margin-top: 10px;

      &-row {
        padding: 16px 0;
        border-top: 1px solid $divider;

        > div {
          position: relative;
          margin-bottom: 25px;
          line-height: 24px;
          padding-left: 100px;
        }

        .heading {
          position: absolute;
          top: 0;
          left: 0;
          max-width: 100px;
          font-weight: 700;
        }

        img {
          width: 60px;
          height: 30px;
        }

        a {
          font-weight: 600;
          color: $cccs-blue;
          text-decoration: none;
        }

        .status {
          color: $cccs-blue;
        }
      }
    }
  }

  .accordion {
    &__controller {
      i {
          /*content:"\e93f";*/
          -webkit-transform:translate(0, -50%) rotate(180deg) !important;
          transform:translate(0, -50%) rotate(180deg) !important;
      }
    }

    &__content{
      display:block !important;
    }
  }

  .video-gallery {
    &-item {
      width: 25%;
    }
  }
}

@media only print and (min-device-width: 1280px) {
  .banner-carousel  {
    &__text {
      width: calc(100% - 600px);
    }
  }
}


//Import modules styles
@import '../_modules/table-responsive/table-responsive';
@import '../_modules/page-not-found/page-not-found';

//Atoms
@import '../_modules/atoms/back-to-top/back-to-top';

//Molecules
@import '../_modules/molecules/header-quicklinks/header-quicklinks';
@import '../_modules/molecules/breadcrumb/breadcrumb';
@import '../_modules/molecules/side-nav/side-nav';
@import '../_modules/molecules/help-widget-tabs/help-widget-tabs';
@import '../_modules/molecules/our-people-item/our-people-item';
@import '../_modules/molecules/know-people-item/know-people-item';
@import '../_modules/molecules/publications-item/publications-item';
@import '../_modules/molecules/year-filter/year-filter';
@import '../_modules/molecules/pagination/pagination';
@import '../_modules/molecules/faq-search/faq-search';
@import '../_modules/molecules/accordion/accordion';
@import '../_modules/molecules/video-gallery-item/video-gallery-item';
@import '../_modules/molecules/related-listing-item/related-listing-item';
@import '../_modules/molecules/resources-listing-item/resources-listing-item';
@import '../_modules/molecules/public-register-item/public-register-item';
@import '../_modules/molecules/show-sort-filter/show-sort-filter';
@import '../_modules/molecules/related-pages-item/related-pages-item';
@import '../_modules/molecules/public-updates-search/public-updates-search';
@import '../_modules/molecules/faq-side-nav/faq-side-nav';

//Organisms
@import '../_modules/organisms/site-header/site-header';
@import '../_modules/organisms/site-footer/site-footer';
@import '../_modules/organisms/main-nav/main-nav';
@import '../_modules/organisms/social-sharing-widget/social-sharing-widget';
@import '../_modules/organisms/banner-carousel/banner-carousel';
@import '../_modules/organisms/case-registry-widget/case-registry-widget';
@import '../_modules/organisms/help-widget/help-widget';
@import '../_modules/organisms/latest-resources/latest-resources';
@import '../_modules/organisms/our-people-widget/our-people-widget';
@import '../_modules/organisms/know-people-widget/know-people-widget';
@import '../_modules/organisms/publications-widget/publications-widget';
@import '../_modules/organisms/listing-table-widget/listing-table-widget';
@import '../_modules/organisms/video-gallery-widget/video-gallery-widget';
@import '../_modules/organisms/careers-banner/careers-banner';
@import '../_modules/organisms/resources-banner/resources-banner';
@import '../_modules/organisms/related-listing-widget/related-listing-widget';
@import '../_modules/organisms/resources-listing-widget/resources-listing-widget';
@import '../_modules/organisms/public-register-widget/public-register-widget';
@import '../_modules/organisms/public-updates-widget/public-updates-widget';
@import '../_modules/organisms/related-pages-widget/related-pages-widget';
@import '../_modules/organisms/landing-widget/landing-widget';
@import '../_modules/organisms/rte/rte';
@import '../_modules/organisms/faq/faq';
