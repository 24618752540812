// back-to-top styles
.back-to-top {
	position: absolute;
	right: 30px;
	width: 40px;
	height: 40px;
	border: none;
	background-color: $black-box;
	color: #fff;
	transform: translate(0, -50%);
	cursor: pointer;
	z-index: 10;
	text-decoration: none;

	&:focus { outline: 0; }

	i {
		display: inline-block;
		font-size: calc-em(20px);
		position: absolute;
		top: 12px;
		left: 12px;
	}
}

@media only screen and (min-width: $bpTablet){
	.back-to-top{ right: 0; }
}


@media only screen and (min-width: $bpDesktop) {
	.back-to-top {
		@include transition(all .35s ease-in-out);

		&:hover {
			@include transition(all .35s ease-in-out);
			box-shadow: 0 0 6px 1px rgba(100, 100, 100, 0.2);
		}
	}
}
