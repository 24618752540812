[class^="col-"], [class*=" col-"], [class^="col10-"], [class*=" col10-"] {
	float: left;
	padding: 0;
	width: 100%;
}

.row {
	@extend %clearfix;
	margin: 0 -10px;
}

// .reverse {
// 	float: right;
// }

@media only screen and (max-width: $bpTablet - 1) {
	@for $i from 1 through 12 {
		.col-xs-#{$i} {
			width: (100% / 12 * $i);
		}

		.offset-xs-#{$i} {
			margin-left: (100% / 12 * $i);
		}
	}
}

@media only screen and (min-width: $bpMdMobile) and (max-width: $bpTablet - 1) {
	@for $i from 1 through 12 {
		.col-lxs-#{$i} {
			width: (100% / 12 * $i);
		}

		.offset-lxs-#{$i} {
			margin-left: (100% / 12 * $i);
		}
	}
}

@media only screen and (min-width: $bpTablet) {
	@for $i from 1 through 12 {
		.col-sm-#{$i} {
			width: (100% / 12 * $i);
		}

		.offset-sm-#{$i} {
			margin-left: (100% / 12 * $i);
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	@for $i from 1 through 12 {
		.col-md-#{$i} {
			width: (100% / 12 * $i);
		}

		.offset-md-#{$i} {
			margin-left: (100% / 12 * $i);
		}
	}
}

@media only screen and (min-width: $bpLgDesktop) {
	@for $i from 1 through 12 {
		.col-lg-#{$i} {
			width: (100% / 12 * $i);
		}

		.offset-lg-#{$i} {
			margin-left: (100% / 12 * $i);
		}
	}
}

//col10

@media only screen and (max-width: $bpTablet - 1) {
	@for $i from 1 through 10 {
		.col10-xs-#{$i} {
			width: (100% / 10 * $i);
		}
	}
}

@media only screen and (min-width: $bpTablet) {
	@for $i from 1 through 10 {
		.col10-sm-#{$i} {
			width: (100% / 10 * $i);
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	@for $i from 1 through 10 {
		.col10-md-#{$i} {
			width: (100% / 10 * $i);
		}

		.offset-md10-#{$i} {
			margin-left: (100% / 10 * $i);
		}
	}
}

@media only screen and (min-width: $bpLgDesktop) {
	@for $i from 1 through 10 {
		.col10-lg-#{$i} {
			width: (100% / 10 * $i);
		}
	}
}
