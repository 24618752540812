// video-gallery-item styles
.video-gallery-item {
	padding: 0 20px;
	margin-bottom: 35px;

	a {
		display: block;
		text-decoration: none;
		color: $text-black;
		cursor: pointer;
	}

	.video-gallery {
		&__image {
			display: inline-block;
			position: relative;
			width: 100%;
			@include aspect-ratio(350, 190, 100%);

			img {
				@include image-cover;
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		&__content {
			p {
				margin: 0;
			}

			.title {
				margin-top: 25px;
				margin-bottom: 15px;
				font-size: calc-em(19px);
				color: $cccs-blue;
				font-weight: 600;
			}
		}
	}
}

@media only screen and (min-width: $bpTablet) {
	.video-gallery-item {
		margin-bottom: 55px;
	}
}

@media only screen and (min-width: $bpDesktop) {
	.video-gallery-item {
		margin-bottom: 55px;

		.video-gallery {
			&__image {
				overflow: hidden;

				img {
					@include transition(all .35s ease-in-out);

					&:hover {
						@include transition(all .35s ease-in-out);
						transform: scale(1.1);
					}
				}

				&:focus {
					outline: none;
				}
			}

			&__content {
				.title {
					@include transition(all .35s ease-in-out);

					&:hover {
						@include transition(all .35s ease-in-out);
						text-decoration: underline;
					}
				}
			}
		}
	}
}
