//functions

//font size
@function calc-em($target, $context: $base-fontsize) {
    @return $target / $context * 1em;
}

//mixins

@mixin visuallyHidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@mixin transition($values...) {
    $transitions: ();
    @each $declaration in $values {
        $prop: nth($declaration, 1);
        $prop-opts: ();
        $length: length($declaration);
        @for $i from 2 through $length {
            $prop-opts: append($prop-opts, nth($declaration, $i));
        }
        $trans: ($prop, $prop-opts);
        $transitions: append($transitions, $trans, comma);
    }

    -webkit-transition: trans-prefix($transitions, webkit);
    -moz-transition: trans-prefix($transitions, moz);
    -o-transition: trans-prefix($transitions, o);
    transition: $values;
}

@mixin border-radius($radius) {
       -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
            border-radius: $radius;
}

@mixin rotate($deg) {
    -webkit-transform: rotate($deg);
       -moz-transform: rotate($deg);
        -ms-transform: rotate($deg);
         -o-transform: rotate($deg);
            transform: rotate($deg);
}

@mixin transform($param) {
    -webkit-transform: $param;
       -moz-transform: $param;
        -ms-transform: $param;
         -o-transform: $param;
            transform: $param;
}

@mixin vertical-middle {
    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        width: 0;
        vertical-align: middle;
    }
}

@mixin aspect-ratio($x, $y, $z) {
    position: relative;
    padding-top: $y / $x * $z;
}

@mixin scale ($scale: 1, $origin: center) {
    -webkit-transform: scale($scale);
        -ms-transform: scale($scale);
            transform: scale($scale);

    @if ($origin != center) {
        @include origin($origin);
    }
}

@mixin absolute-center {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

@mixin center-block($width) {
    margin-left: auto;
    margin-right: auto;
    width: $width;
}

@mixin circle-image {
    border-radius: 50%;
    max-width: 100%;
    height: auto;
}

@mixin normalise-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

@mixin normalise-button {
    background-color: rgba(0,0,0,0);
    border: none;
    padding: 0;
    outline: 0;
    cursor: pointer;
}

@mixin background-cover {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

@mixin image-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

@mixin clearfix {
    &:before, &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }
}

@mixin box-shadow($horizontalOffset, $verticalOffset, $blurRadius, $spreadRadius, $color) {
    -webkit-box-shadow: $horizontalOffset $verticalOffset $blurRadius $spreadRadius $color;
        -moz-box-shadow: $horizontalOffset $verticalOffset $blurRadius $spreadRadius $color;
            box-shadow: $horizontalOffset $verticalOffset $blurRadius $spreadRadius $color;
}

@mixin icomoon($glyph) {
    @extend %icomoon;
    content: $glyph;
}

@mixin mobile {
    @media (max-width: $xs-breakpoint - 1) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: $sm-breakpoint - 1) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: $md-breakpoint - 1) {
        @content;
    }
}

@mixin lgDesktop {
    @media (max-width: $lg-breakpoint - 1) {
        @content;
    }
}
