// help-widget styles
.help-widget {
	@extend %clearfix;
	margin-top: 40px;
	margin-bottom: 27px;

	h3 {
		text-align: center;
		font-weight: 700;
		font-size: calc-em(16px);
		margin: 0;
		margin-bottom: 30px;
	}

	&__content {
		display: none;
		opacity: 0;
		border-top: 1px solid $light-grey-2;
		margin-top: -28px;
		padding-top: 24px;

		&.transit-in {
			opacity: 1;
		}

		.row {
			margin: 0;
		}
	}

	&__item {
		width: 100%;
		display: inline-block;

		a {
			display: block;
			background: $grey-box2;
			text-decoration: none;
			color: $text-black;
			padding: 20px 0 20px 20px;
			margin-bottom: 13px;
			position: relative;
			letter-spacing: 0;
			font-weight: 500;

			p {
				margin: 0;
				padding-right: 40px;
				font-size: calc-em(15px);
			}

			i {
				position: absolute;
				right: 20px;
				top: 50%;
				transform: translate(0, -50%);
			}
		}
	}
}


@media only screen and (min-width: $bpDesktop) {
	.help-widget {
		margin-bottom: 70px;
		margin-top: 75px;

		h3 {
			font-size: calc-em(28px);
			font-weight: 600;
			margin-bottom: 35px;
		}

		&__content {
			border: none;
		}


		&__col {
			&:nth-child(odd) {
				.help-widget__item {
					padding: 0 10px 0 105px;
				}
			}

			&:nth-child(even) {
				.help-widget__item {
					padding: 0 105px 0 10px;
				}
			}
		}

		&__item {
			a {
				@include transition(all .35s ease-in-out);

				&:hover {
					@include transition(all .35s ease-in-out);
					// box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.26);
					box-shadow: 0 0 6px 1px rgba(100, 100, 100, 0.2);
					background: $cccs-blue;
					text-decoration: underline;
					color: #fff;

					i {
						color: #fff;
					}
				}

				p {
					font-size: calc-em(18px);
					font-weight: normal;
				}
			}
		}
	}
}
