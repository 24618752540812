// pagination styles
.pagination {
	text-align: center;

	ul {
		@extend %clearfix;
		@extend %list-unstyled;
		margin: 30px 0 75px;
		display: inline-block;
	}
	
	&.listing-table-pagination {
		ul {
			margin: 0 0 75px;
		}
	}

	li {
		float: left;
		display: inline-block;
		// padding: 0 8px;
		// width: 34px;
		// height: 34px;
		border-radius: 50%;
		background: transparent;
		@include transition(all .35s ease-in-out);

		&.active, &:hover {
			cursor: pointer;
			@include transition(all .35s ease-in-out);
			background-color: $cccs-blue;

			a {
				color: #fff;
			}
		}
	}

	a {
		font-size: calc-em(19px);
		text-decoration: none;
		color: $text-black;
		font-weight: 400;
		// line-height: 41px;
		width: 38px;
		height: 38px;
		padding: 8px 11px;
		display: block;
	}
}

@media only screen and (min-width: $bpLgMobile) {
	.pagination {
		li {
			// padding: 0 12px;
		}
	}
}
