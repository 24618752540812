// know-people-item styles
.know-people-wrapper {
	.row {
		margin: 0 -20px;
	}

	.know-people-item {
		padding-bottom: 20px;
		padding: 0 20px;
		margin-bottom: 35px;

		img {
			@include image-cover;
			vertical-align:bottom;
		}

		a {
			text-decoration: none;
			color: $text-black;
			display: block;
		}

		&__text {
			margin-top: 16px;

			p {
				line-height: 23px;
				margin: 0;
				font-weight: 600;
			}
		}

		&:last-child {
			margin: 0;
		}
	}
}

// @media only screen and (min-width: $bpTablet) {
// 	.know-people-wrapper {
// 		.know-people-item {
// 			&:nth-last-child(2) {
// 				margin: 0;
// 			}
// 		}
// 	}
// }

@media only screen and (min-width: $bpDesktop) {
	.know-people-wrapper {
		.know-people-item {
			// padding: 0 20px;

			&__image {
				overflow: hidden;
				height: 280px;

				img {
					@include transition(all .35s ease-in-out);

					&:hover {
						cursor: pointer;
						@include transition(all .35s ease-in-out);
						transform: scale(1.1);
					}
				}
			}

			&__text {
				p {
					@include transition(all .35s ease-in-out);
				}

				&:hover {
					p {
						@include transition(all .35s ease-in-out);
						text-decoration: underline;
					}
				}
			}
		}
	}
}
