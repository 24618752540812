// breadcrumb styles
.breadcrumb {
	border-top: 1px solid $box-outline;
	padding: 12px 0;

	ul {
		@extend %clearfix;
		@extend %list-unstyled;
		@include vertical-middle;
		// height: 55px;
		font-size: calc-em(14px);
		// border-bottom: 1px solid $box-outline;

		.home {
			.icon-home {
				color: $cccs-blue;
				font-weight: 600;
				font-size: calc-em(16px);
			}

			&:before {
				display: none;
			}
		}

		li {
			display: inline-block;
			margin-right: 5px;

			a {
				text-decoration: none;
				color: $cccs-blue;
				font-weight: 600;
				letter-spacing: 0;
				line-height: 24px;
			}

			&.active {
				a {
					color: $text-black;
					font-weight: normal;
				}
			}

			&:before {
				content: '>';
				padding-right: 7px;
				color: #000;
			}

			&:last-child {

				&:after {
					display: none;
				}
			}
		}
	}
}
