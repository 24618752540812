// related-pages-widget styles
.related-pages {
	position: relative;
	margin-top: 75px;
	padding-top: 65px;
	border-top: 1px solid $box-outline;

	h4 {
		margin: 0;
		margin-bottom: 54px;
	}

	&__carousel {
		position: absolute;
		top: 65px;
		right: 0;
	}

	.carousel-topBtn {
		position: relative;
		display: inline-block;
		transition: 0.3s all;
		background: none;
		border: none;
		padding: 15px 17px;
		cursor: pointer;
		background-color: $cccs-blue;
		color: #fff;
		float: left;
		outline: none;
		z-index: 10;

		i {
			font-size: calc-em(14px);
		}

		&.slick-disabled {
			// background-color: $ads-grey;
			cursor: default;

			i {
				color: #fff;
			}
		}

		&.carousel-arrow-prev {
			margin-right: 12px;
		}

		&.display-none {
			display: none;
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.related-pages {
		margin: 65px 0 0 55px;

		.carousel-topBtn {
			@include transition(all .35s ease-in-out);

			&:hover {
				@include transition(all .35s ease-in-out);
				box-shadow: 0 0 10px 1px rgba(100, 100, 100, 0.2);
			}
		}

		&__list {
			margin: 0 -35px;

			.slick-list {
				.slick-slide {
					padding: 0 35px;
					// &:nth-child(odd) {
					// 	padding-right: 35px;
					// }

					// &:nth-child(even) {
					// 	padding-left: 35px;
					// }
				}

				.slick-track {
					&.slick-count-one {
						width: 100% !important;
					}
				}
			}
		}
	}
}
@media print {
	.related-pages {
		padding-top: 35px;
		margin: 0;
		page-break-inside: avoid;

		h4 {
			margin-bottom: 30px;
		}
	}
}
