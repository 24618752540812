// year-filter styles
.year-filter {
	@extend %clearfix;
	position: relative;
	margin-bottom: 36px;

	&__selected {
		position: relative;
		display: inline-block;
		width: 100%;
		padding: 15px 20px;
		border: 1px solid $divider;
		color: $text-black;
		cursor: pointer;
		font-weight: 400;

		i {
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translate(0, -50%);
			@include transition(all .35s ease-in-out);
			color: $text-black;
			font-size: calc-em(12px);
		}

		.rotate {
			transform: translate(0, -50%) rotate(-180deg);
			@include transition(all .35s ease-in-out);
		}
	}

	ul {
		@extend %list-unstyled;
		position: absolute;
		visibility: hidden;
		opacity: 0;
		@include transition(all .35s ease-in-out);
		border: 1px solid $divider;
		border-top: none;
		z-index: 2;
		width: 100%;

		&.show {
			visibility: visible;
			opacity: 1;
			@include transition(all .35s ease-in-out);
		}
	}

	li {
		width: 100%;
		background-color: #fff;
		font-weight: 400;
		text-align: left;
		cursor: pointer;

		a {
			display: block;
			padding: 10px 20px;
			text-decoration: none;
			color: $text-black;
			@include transition(all .35s ease-in-out);
		}

		&:hover {
			@include transition(all .35s ease-in-out);
			background-color: $cccs-blue;

			a {
				color: #fff;
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.year-filter {
		&__wrapper {
			float: right;
		}

		&__selected {
			width: 200px;
		}

		ul {
			width: auto;
		}

		li {
			width: 198px;
		}
	}
}
