// side-nav styles
.side-nav {
	font-size: calc-em(16px);
	margin-bottom: 60px;

	ul {
		@extend %list-unstyled;
		@include clearfix;
	}

	&__lvl1 > li {
		background-color: $sidebar-grey;
		position: relative;
		margin-bottom: 3px;
		@include transition(all .35s ease-in-out);

		> a {
			display: inline-block;
			width: 100%;
			padding: 15px 30px 15px 15px;
			// padding: 15px;
			text-decoration: none;
			color: #000;
			position: relative;
			letter-spacing: 0;
			color: $text-black;
		}

		i {
			position: absolute;
			display: inline-block;
			padding: 18px;
			top: 0;
			right: 0;
			transform: rotate(90deg);
			@include transition(all .35s ease-in-out);
			cursor: pointer;
			font-size: calc-em(10px);
		}

		&:hover {
			@include transition(all .35s ease-in-out);
			background-color: $cccs-blue;

			> a, > i {
				color: #fff;
			}

			&:focus .side-nav__lvl2 {
				color: $text-black;

				a {
					color: $cccs-blue;
				}
			}
		}

		.side-nav__lvl2 {
			display: none;
		}

		&.expanded {
			background: #fff;
			color: $text-black;

			> a {
				background: $cccs-blue;
				color: #fff;
			}

			> i {
				transform: rotate(-90deg);
				@include transition(all .35s ease-in-out);
				color: #fff;
			}

			.side-nav__lvl2 {
				position: relative;
				a {
					color: $text-black;
					display: block;
					span {
						display: block;
					}

					&:hover {
						span {
							color: $cccs-blue;
						}
					}

					&.active {
						span {
							color: $cccs-blue;
						}
					}
				}
			}
		}
	}
	&__lvl2 {
		@include clearfix;
	}

	&__lvl2 > li {
		// padding: 15px 0 15px 34px;
		// background-color: #fff;
		position: relative;
		display:block;

		> a {
			display: block;
			width: 100%;
			text-decoration: none;
			color: $text-black;
			padding: 10px 0 10px 35px;
			position: relative;
			// @include transition(all .35s ease-in-out);

			span {
				// margin: 0;
				// padding-left: 20px;
				padding-right: 40px;
				z-index: 1;
				position: relative;
				display: block;
			}

			&:before {
				background: $siderbar-line;
				content: "";
				display: block;
				height: 1px;
				position: absolute;
				top: 50%;
				width: 15px;
				z-index: 0;
				margin-left: -20px;
			}

			&.active {
				color: $cccs-blue;
				font-weight: 600;

				> span {
					color: $cccs-blue;
				}
			}

			&:hover {
				// @include transition(all .35s ease-in-out);
				color: $cccs-blue;
			}
		}

		&:before {
			content: "";
			position: absolute;
			border-left: 1px solid $siderbar-line;
			height: 100%;
			left: 15px;
			background: none;
			display: inline-block;
		}

		&:first-child {
			&:before {
				width: 50%;
				top: 50%;
				height: 50%;
				
			}

			+ .side-nav__lvl2--li:last-child:before {
				height: 50%;
				top: 0;
			}
		}

		> .side-nav__lvl3 > li:only-child:before {
			height: 100%;
		}

		&:only-child {
			> .side-nav__lvl3 > li:only-child:before {
				height: 50%;
			}
		}

		&:last-child {
			&:before {
				// height: 50%;
				height: 100%;
    			top: -50%;
			}

			> .side-nav__lvl3 > li:only-child:before {
				height: 50%;
			}
		}

		&:only-child:before {
			height: 0;
		}

		i {
			position: absolute;
			display: inline-block;
			padding: 15px;
			top: 0;
			// right: 0;
			z-index: 1;
			transform: rotate(90deg);
			@include transition(all .35s ease-in-out);
			cursor: pointer;
			font-size: calc-em(10px);
		}

		&.expanded-child {
			i {
				transform: rotate(-90deg);
				@include transition(all .35s ease-in-out);
			}

			> a {
				span {
					color: $cccs-blue;
				}
			}
		}

		&.one-class {
			&:before {
				content: none;
			}
		}
	}

	&__lvl3 > li {
		background-color: #fff;
		position: relative;

		> a {
			padding: 10px 0 10px 50px;
			display: inline-block;
			width: 100%;
			// padding: 0 15px;
			text-decoration: none;
			color: $text-black;
			// @include transition(all .35s ease-in-out);

			p {
				margin: 0;
				padding-left: 35px;
				position: relative;
			}

			&:before {
				background: $siderbar-line;
				content: "";
				display: block;
				height: 1px;
				position: absolute;
				top: 50%;
				left: 15px;
				width: 28px;
			}

			&.active {
				color: $cccs-blue;
				font-weight: 600;

				> span {
					color: $cccs-blue;
				}
			}

			&:hover {
				@include transition(all .35s ease-in-out);
				color: $cccs-blue;
			}
		}

		&:before {
			content: "";
			position: absolute;
			border-left: 1px solid $siderbar-line;
			height: 100%;
			height: var(--height, 100%);
			// height: 100%;
			left: 15px;
			// top: var(--top, -50%);
			// top: -50%;
		}

		&:after {
			content: "";
			position: absolute;
			border-left: 1px solid $siderbar-line;
			// height: 100%;
			left: 15px;
			// top: -50%;
		}

		&.lvl3-last-element {
			&:after {
				content: none;
			}
		}

		&:last-child {
			&:before {
				// height: 100%;
				height: 50%;
			}
		}

		// &:only-child:before {
		// 	height: 100%;
		// }
	}

	&__lvl3 {
		display: none;
		opacity: 0;
		@extend %list-unstyled;
		// @include transition(all .35s ease-in-out);
		position: relative;
	}

	&__lvl2--li {
		&.sub-expanded {
			&:last-child:before {
				height: 100%;
				top: 0;
				// @include transition(all .35s ease-in-out);
			}
			.side-nav__lvl3 {
				// @include transition(all .35s ease-in-out);
				display: block;
				opacity: 1;
				position: relative;
			}

			&:first-child:before {
				top: 30%;
			}

			> i {
				transform: rotate(-90deg);
				@include transition(all .35s ease-in-out);
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.side-nav {
		margin: 0;
		margin-bottom: 32px;
	}
}
