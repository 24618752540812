// rte styles
.rte {
	@extend %clearfix;
	color: $text-black;

	> * {
		margin-bottom: 30px;
	}

	h1 {
		color: $cccs-blue;
		margin: 0;
		margin-bottom: 24px;
	}

	h2 {
		font-size: calc-em(28px);
		color: $cccs-blue;
		margin: 0;
		margin-bottom: 18px;
	}

	.italic {
		font-style: italic;
	}

	.strong {
		font-weight: 600;
	}

	.underline {
		text-decoration: underline;
	}

	p {
		margin: 0;
		line-height: 26px;
		letter-spacing: 0.3px;
		margin-bottom: 30px;
	}

	a {
		color: $cccs-blue;
	}

	ul {
		padding-left: 50px;
		margin: 0;
		margin-bottom: 30px;

		li {
			padding-left: 30px;
			line-height: 24px;
			letter-spacing: 0.3px;
		}
	}

	table {
		width: 100% !important;
		margin: 0 !important;

		th, td {
			padding: 13px 11px !important;
			border-style: solid;
			border: 1px solid grey !important;
			color: $text-black;
			vertical-align: top;

			p, span {
				color: $text-black !important;
				line-height: unset;
			}
		}
	}

	> *:last-child {
		margin-bottom: 0;
	}
}

@media only screen and (min-width: $bpDesktop) {
	.rte {
		padding: 0 0 38px 55px;

		h3 {
			margin-bottom: 38px;
		}

		p {
			margin-bottom: 38px;
		}

		ul {
			margin-bottom: 38px;
		}
	}
}
