// resources-banner styles
.resources-banner {
	position: relative;

	&__image {
		height: 470px;

		img {
			@include image-cover;
		}
	}

	&__wrapper {
		position: absolute;
		top: 30px;
		left: 0;
		right: 0;
	}

	&__form {
		background: #fff;
		position: relative;
		padding: 15px 30px 25px 30px;

		h2 {
			text-align: center;
			margin: 0;
			padding-bottom: 20px;
		}
	}

	&__search {

		.row {
			margin: 0 -20px;
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.form-group {
			display: inline-block;
		}

		.resourcesSearchBox {
			width: calc(100% - 20px);
			margin-left: 10px;
			margin-right: 10px;
		}

		#resourcesSearchBox {
			height: 44px;
			width: 100%;
			border: 0;
			background: $light-grey;
			padding-left: 10px;
			text-shadow: $text-black;
			font-family: $primaryfont;
			color: $text-black;
			font-weight: 600;
			display: inline-block;

			&:focus {
				border: none;
				outline: none;
			}
		}

		#resourcesSearchBtn {
			display: inline-block;
			border: none;
			outline: none;
			padding: 13px 40px 13px 18px;
			height: 44px;
			font-weight: 600;
			width: 100%;
		}

		.resourcesSearchBtn {
			position: relative;
			// margin: 0 10px;
			// width: calc(100% - 20px);
			width: calc(100% + 20px);
			margin-left: -10px;

			i {
				position: absolute;
				top: 50%;
				right: 10px;
				transform: translate(0, -50%);
				font-size: calc-em(11px);
				font-weight: 600;
			}
		}

		.dropdown-copy {
			position: relative;
			display: block;
			width: 100%;

			> button {
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				line-height: inherit;
				letter-spacing: 0;
				text-align: left;
				position: relative;
				width: 100%;
				height: 44px;
				border: none;
				padding: 6px 35px 6px 10px;
				font-size: calc-em(16px);
				color: $text-black;
				background: $light-grey;
				font-family: $primaryfont;

				&:after {
					transform: rotate(45deg);
					@include transition(all .35s ease-in-out);
					position: absolute;
					top: -4px;
					right: 10px;
					bottom: 0;
					display: block;
					width: 6px;
					height: 6px;
					margin: auto;
					content: "";
					border-right: 1px solid $text-black;
					border-bottom: 1px solid $text-black;
				}

				&:focus {
					border: none;
					outline: none;
				}
			}

			&.rotate {
				button {
					&:after {
						transform: rotate(-135deg);
						@include transition(all .35s ease-in-out);
					}
				}
			}

			ul {
				@extend %list-unstyled;
				position: absolute;
				z-index: 10;
				top: 44px;
				left: 0;
				display: none;
				overflow: auto;
				width: 100%;
				height: 300px;
				margin: 0;
				@include transition(all .35s ease-in-out);

				button {
					text-decoration: none;
					text-align: left;
					color: $text-black;
					background: $light-grey;
					font-family: $primaryfont;
					display: block;
					width: 100%;
					padding: 8px 5px 8px 10px;
					border: 0;
					border-bottom: 1px solid $light-grey;

					&:hover {
						cursor: pointer;
						background-color: $cccs-blue;
						color: #fff;
					}
				}
			}
		}

		.select-dropdown {
			position: relative;
			padding: 0 10px;
			margin-bottom: 10px;
			width: 100%;

			&:last-child {
				margin: 0;
			}
		}

		select {
			position: absolute;
			display: block;
			top: 0;
			opacity: 0;
			height: 40px;
			margin: 0 0 -40px;
			width: 100%;
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.resources-banner {
		position: relative;

		&__image {
			height: 400px;
		}

		&__wrapper {
			top: 70px;
		}

		&__form {
			padding: 35px 68px 55px 68px;

			h2 {
				padding-bottom: 30px;
			}
		}

		&__search {

			.row {
				margin-bottom: 22px;
			}

			.resourcesSearchBox {
				width: calc(100% - 145px);
				margin-left: 10px;
				margin-right: 10px;
			}

			#resourcesSearchBtn {
				@include transition(all .35s ease-in-out);

				&:hover {
					cursor: pointer;
					@include transition(all .35s ease-in-out);
					box-shadow: 0 0 6px 1px rgba(100, 100, 100, 0.2);
				}
			}

			.resourcesSearchBtn {
				position: absolute;
				width: 110px;
				top: 101px;
				right: 60px;
			}

			.three-dropdown {
				width: calc(33.33% - 3px);
			}

			.four-dropdown {
				width: calc(25% - 3px);
			}

			select {
				width: calc(100% - 10px);
				visibility: hidden;
			}
		}
	}
}
