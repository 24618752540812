// site-footer styles
.site-footer {
	background-color: $cccs-blue;
	color: $text-white;
	font-size: calc-em(12px);
	// font-weight: 400;
	position: relative;

	&.bg-overlay {
	  &:before {
	    content: "";
	    display: block;
	    position: absolute;
	    width: 100%;
	    height: 100%;
	    left: 0;
	    top: 0;
	    background: #000000;
	    z-index: 80;
	    opacity: 0.5
	  }
	}

	&__row {
		&--name {
			margin: 40px 0 15px 0;
			// text-align: center;
		}
	}

	&__top {
		@extend %clearfix;
		border-bottom: 1px solid #fff;

		&--left {
			margin-top: 29px;
		}

		&--right {
			margin-bottom: 16px;
		}

		ul {
			@extend %list-unstyled;
			// text-align: center;
			display: block;
		}

		li {
			display: block;
			margin-bottom: 15px;

			a {
				text-decoration: none;
				color: $text-white;
			}

			// &:last-child {
			// 	margin-bottom: 28px;
			// }
		}

		.last-updated {
			margin: 0;
			// text-align: center;
		}

		.footer-links__social {
			margin-bottom: 28px;

			li {
				display: inline-block;
				margin: 0;

				a {
					padding: 5px 10px 5px 0;
				}
			}
		}
	}

	&__bottom {
		@extend %clearfix;
		// text-align: center;
		margin-top: 17px;

		ul {
			@extend %list-unstyled;
			// text-align: center;
			display: block;
		}

		li {
			display: block;
			margin-bottom: 15px;

			a {
				text-decoration: none;
				color: $text-white;
			}
		}

		&--right {
			margin-top: 26px;
			p {
				margin: 0;
				margin-bottom: 15px;
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.site-footer {
		text-align: left;
		font-size: calc-em(14px);

		&__row {
			&--name {
				text-align: left;
				margin: 40px 0 12px 0;
			}

			&--links {
				float: right;

				ul {
					text-align: left;
					display: inline-block;
					float: left;
				}

				li {
					display: inline-block;
					margin-left: 20px;
					margin-bottom: 0;
				}
			}
		}

		&__top {
			.footer-links {
				margin-right: 10px;
			}

			.footer-links__social {
				margin-right: 0;

				li {
					a {
						padding: 5px 10px;
					}
				}
			}
		}

		// &__top {
		// 	// margin: 40px 0 0 0;

		// 	ul {
		// 		text-align: left;
		// 		display: inline-block;
		// 		float: left;
		// 	}

		// 	li {
		// 		display: inline-block;
		// 		margin-right: 20px;
		// 		margin-bottom: 0;
		// 	}

		// 	// &--left, &--right {
		// 	// 	margin: 40px 0 0 0;
		// 	// }

		// 	.last-updated {
		// 		text-align: right;
		// 	}
		// }

		&__bottom {
			margin: 24px 0 30px 0;
			text-align: right;

			&--left {
				float: left;

				ul {
					text-align: left;
					display: inline-block;
					float: left;
				}

				li {
					display: inline-block;
					margin-right: 20px;
					margin-bottom: 0;
				}
			}

			&--right {
				float: right;
				margin-top: 0;

				p {
					margin: 0;
					margin-bottom: 8px;
					line-height: 12px;
				}
			}

			// .copyright {
			// 	float: right;
			// 	margin: 0;
			// }

			// .best-viewed {
			// 	float: right;
			// 	margin: 0;
			// }

		}
	}
}

// @media only screen and (min-width: $bpMdDesktop) {
// 	.site-footer {
// 		&__top {

// 			li {
// 				display: inline-block;
// 				margin-right: 30px;
// 			}
// 		}
// 	}
// }

