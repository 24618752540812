// header-quicklinks styles
.govnav {
	.logo {
		display: inline-block;

		img {
			height: 30px;
		}
	}

	&-links {
		@extend %list-unstyled;
		margin-top: 4px;

		li {
			display: inline-block;

			a {
				text-decoration: none;
				font-size: calc-em(12px);
				color: $text-black;
				margin-left: 8px;
				letter-spacing: 0;
			}
		}
	}

	&-form {
		position: relative;
		float: right;
		margin-top: 13px;

		#siteSearch {
			height: 33px;
			width: 230px;
			border: 0;
			background: $light-grey;
			padding-left: 36px;
			font-size: calc-em(12px);
			text-shadow: $text-black;
			font-family: $primaryfont;
			color: $text-black;
			font-weight: 600;
		}

		.btn-search {
			position: absolute;
			left: 2px;
			top: 0;
			z-index: 1;
			height: 33px;
			width: 36px;
			border: 0;
			padding: 7px;
			background: none;
			text-align: center;
			font-size: 1.2em;
			color: $grey;
		}

		.filter-select {
			display: inline-block;

			#searchWithin {
				padding: 8px;
				border: none;
				background: $light-grey;
				color: $text-black;
				font-size: calc-em(12px);
				font-family: $primaryfont;
			}
		}
	}
}

@media only screen and (min-width: 768px) {
	.govnav {
		float: right;
		text-align: right;
	}
}
