// resources-listing-item styles
.resources-listing-item {
	@extend %clearfix;
	border-bottom: 1px solid $box-outline;
	padding-bottom: 35px;
	margin-bottom: 35px;

	&__image {
		@include aspect-ratio(108, 122, 100%);

		img {
			@include image-cover;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__text {
		margin-top: 10px;

		p {
			margin: 0;
			margin-bottom: 22px;
			color: $text-grey;
			line-height: 24px;
		}

		.title {
			font-weight: 600;
			font-size: calc-em(18px);
		}

		a {
			text-decoration: none;
			color: $text-black;
			font-weight: 600;

			i {
				margin-right: 17px;
				color: $cccs-blue;
			}

			.size {
				&:before {
					content: "(";
					padding-left: 3px;
					position: relative;
					font-weight: 600;
				}

				&:after {
					content: ")";
					position: relative;
					font-weight: 600;
				}
			}
		}
	}

	&:last-child {
		border: none;
		padding: 0;
		margin-bottom: 80px;
	}
}

@media only screen and (max-width: $bpDesktop - 1) {
	.resources-listing-item {
		.resources-noimage {
			display: none;
		}
	}
}

@media only screen and (min-width: $bpTablet) {
	.resources-listing-item {
		width: calc(50% - 15px);
		display: inline-block;
		margin-right: 15px;

		&:nth-child(even) {
			margin-right: 0;
			margin-left: 10px;
		}

		&.second-last-child {
			border: none;
			padding: 0;
			margin-bottom: 80px;
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.resources-listing-item {
		width: 100%;
		margin-right: 0;
		margin-left: 0;

		&:nth-child(even) {
			margin-right: 0;
			margin-left: 0;
		}

		&__image {
			@include aspect-ratio(108, 122, 100%);
		}

		&__text {
			// margin-left: 25px;
			// margin-bottom: 35px;
			margin: 0 0 35px 25px;
		}
	}
}
