// careers-banner styles
.careers-banner {
	@extend %clearfix;

	&__item {
		background: $grey-box;
		position: relative;
	}

	&__text {
		padding: 25px 0;

		h1 {
			font-size: calc-em(18px);
			margin: 0;
			margin-bottom: 22px;
		}

		a {
			text-decoration: none;
			font-weight: 700;
			letter-spacing: 0;
			font-size: calc-em(18px);

			i {
				position: absolute;
				right: 15px;
				top: 50%;
				font-size: calc-em(10px);
				transform: translateY(-50%);
				font-weight: 900;
			}
		}
	}

	&__image {
		position: relative;
		height: 160px;

		img {
			@include image-cover;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

@media only screen and (min-width: $bpTablet) {
	.careers-banner {
		&__image {
			height: 260px;
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.careers-banner {
		&__text {
			padding: 60px 30px 55px 0;
			width: 50%;

			h1 {
				font-size: calc-em(26px);
				margin-bottom: 26px;
			}
		}

		&__image {
			position: absolute;
			min-height: 265px;
			width: 50%;
			left: 50%;
			top: 0;
		}

		&__item {
			min-height: 265px;
		}
	}
}
