// latest-resources styles
.latest-resources {
	padding-top: 40px;
	padding-bottom: 48px;
	background: $cccs-background;

	&__title {
		// padding: 0 10px;
		margin: 0px 0 24px 0;

		h2 {
			margin: 0;
			font-weight: 700;
			font-size: calc-em(18px);
		}
	}

	&__wrapper {
		overflow: hidden;
	}

	&__scroll {
		overflow-x: scroll;
		overflow-y: hidden;
		margin: 0 -20px;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: none;

		&::-webkit-scrollbar {
			width: 0px;  /* remove scrollbar space */
			background: transparent;  /* optional: just make scrollbar invisible */
		}
	}

	&__listing {
		min-width: 860px;
		position: relative;
		display: block;
	}

	&__image {
		height: 205px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}

	&__content {
		background: #fff;
		padding: 15px 50px 5px 15px;

		.title {
			margin: 0;
			font-weight: 700;
			font-size: calc-em(14px);
			letter-spacing: 0;
			line-height: 18px;
			margin-bottom: 16px;
		}

		.desc {
			margin: 0;
			font-weight: 400;
			font-size: calc-em(14px);
			letter-spacing: 0;
			line-height: 18px;
			// margin-bottom: 16px;
			padding-bottom: 32px;
		}
	}

	&__download {
		position: absolute;
		bottom: 15px;
		left: 15px;

		i {
			color: $cccs-blue;
			margin-right: 10px;
			display: inline-block;
		}

		span {
			color: $text-black;
			font-weight: 700;
			font-family: $primaryfont;
			letter-spacing: 0;
			font-size: calc-em(13px);
		}
	}

	&__item {
		@extend %clearfix;
		// margin: 0 10px;
		margin-left: 20px;
		width: 260px;
		position: relative;
		background: #fff;

		a {
			text-decoration: none;
			color: $text-black;
			display: block;
		}

		&.featured {
			.latest-resources {
				&__image {
					height: 250px;
				}

				&__content {
					padding: 15px 20px 15px 16px;

					.title {
						font-weight: 700;
					}
			
					.desc {
						font-weight: 400;
					}
				}
			}
		}

		&:last-child {
			// margin: 0;
			margin-right: 20px;
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.latest-resources {
		padding-bottom: 85px;
		padding-top: 90px;

		&__wrapper {
			// padding: 0px 0 42px 0;
			overflow: unset;
		}

		&__title {
			h2 {
				font-size: calc-em(28px);
			}
		}

		&__scroll {
			overflow: unset;

			.is-ie & {
				overflow: visible;
			}
		}

		&__listing {
			min-width: unset;
		}

		&__content {
			padding: 23px 50px 23px 15px;

			p {
				font-size: calc-em(16px);
				@include transition(all .35s ease-in-out);
			}

			&:hover {
				p {
					@include transition(all .35s ease-in-out);
					text-decoration: underline;
				}
			}
		}

		&__item {
			width: unset;
			@include transition(all .35s ease-in-out);

			&:hover {
				@include transition(all .35s ease-in-out);
				box-shadow: 0 0 6px 1px rgba(100, 100, 100, 0.8);
			}

			&.featured {
				width: calc(50% - 20px);

				.latest-resources {

					&__content {
						padding: 28px 20px 28px 25px;

						.title {
							font-size: calc-em(24px);
							margin-bottom: 12px;
						}

						.desc {
							font-weight: 400;
						}
					}
				}
			}

			&.non-featured {
				width: calc(25% - 20px);
			}

			&:last-child {
				margin-right: 0;
			}
		}

		&__image {
			overflow: hidden;

			img {
				@include transition(all .35s ease-in-out);

				&:hover {
					@include transition(all .35s ease-in-out);
					transform: scale(1.1);
				}
			}
		}
	}
}
