// banner-carousel styles
.banner-carousel {
	@extend %clearfix;

	&__item {
		background: $grey-box;
		position: relative;
	}

	&__text {
		padding: 30px 40px 55px 40px;

		p {
			font-size: calc-em(14px);
			margin: 0;
			margin-bottom: 12px;
			text-transform: uppercase;
			color: $cccs-blue;
			font-weight: 400;
		}

		h1 {
			font-size: calc-em(18px);
			margin: 0;
			margin-bottom: 22px;
			line-height: 23px;
		}

		a {
			padding: 13px 30px 13px 13px;
			text-decoration: none;
			font-weight: 700;
			letter-spacing: 0;
			font-size: calc-em(14px);

			i {
				position: absolute;
				right: 15px;
				top: 50%;
				font-size: calc-em(10px);
				transform: translateY(-50%);
				font-weight: 900;
			}
		}
	}

	&__image {
		position: relative;
		height: 160px;

		img {
			@include image-cover;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.slick-list {
		background: $grey-box;
	}

	.slick-dots {
		@extend %list-unstyled;
		vertical-align: middle;
		// top: 190px;
		left: 60px;
		position: absolute;

		li {
			display: inline-block;
			margin-right: 9px;
			vertical-align: middle;
		}

		button {
			width: 8px;
			height: 8px;
			overflow: hidden;
			border: 1px solid $cccs-blue;
			@include border-radius(50%);
			padding: 0;
			background: transparent;
			outline: 0;
			text-indent: 999px;
			vertical-align: middle;
			@include transition(transform .35s ease-in-out, background .35s ease-in-out);
			cursor: pointer;

			&:hover, &:focus {
				@include transition(all .35s ease-in-out);
				box-shadow: 0 0 6px 1px rgba(100, 100, 100, 0.2);
				background: $cccs-blue
			}
		}

		.slick-active {
			button {
				background: $cccs-blue;
			}
		}
	}

	.carousel-btn {
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		background-color: transparent;
		z-index: 1;
		color: #fff;
		width: 38px;
		height: 38px;
		border: none;
		// outline: none;
		cursor: pointer;

		i {
			font-size: calc-em(12px);
			text-align: center;
			display: inline-block;
			position: relative;
			color: $text-black;
		}

		&__prev {
			left: 10px;

			i {
				right: 4px;
				top: -2px;
			}
		}

		&__next {
			right: 10px;

			i {
				right: 2px;
				top: -2px;
			}
		}
	}

}

@media only screen and (min-width: $bpTablet) {
	.banner-carousel {
		&__image {
			height: 260px;
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.banner-carousel {
		&__text {
			// padding: 30px 60px 27px 50px;
			padding: 95px 30px 95px 50px;
			width: 50%;

			h1 {
				font-size: calc-em(26px);
				margin-bottom: 30px;
				line-height: 34px;
			}
		}

		&__image {
			position: absolute;
			min-height: 400px;
			width: 50%;
			left: 50%;
			top: 0;
		}

		&__item {
			min-height: 400px;
		}

		.slick-list {
			background: none;
		}

		.slick-dots {
			display: inline-block;
			position: absolute;
			padding-left: 50px;
			left: 50px;
			@include transition(all .35s ease-in-out);

			button {
				display: block;
			}
		}

		.carousel-btn {
			width: 44px;
			height: 44px;
			top: 50% !important;
			@include transition(all .35s ease-in-out);
			background-color: $cccs-blue;

			&:hover {
				@include transition(all .35s ease-in-out);
				box-shadow: 0 0 6px 1px rgba(100, 100, 100, 0.2);
			}

			&__prev {
				left: 14px;

				i {
					right: 0;
					top: 0;
					color: #fff;
				}
			}

			&__next {
				right: 14px;

				i {
					right: 0;
					top: 0;
					color: #fff;
				}
			}
		}
	}
}

@media only screen and (min-width: $bpMdDesktop) {
	.banner-carousel {
		&__text {
			padding: 95px 30px 95px 80px;
		}

		.carousel-btn {
			width: 44px;
			height: 44px;

			&__prev {
				left: 36px;
			}

			&__next {
				right: 36px;
			}
		}

		.slick-dots {
			padding-left: 80px;
			top: 0;
			left: 80px;
		}
	}
}

@media only screen and (min-width: 1440px) {
	.banner-carousel {
		&__text {
			padding: 95px 60px 95px 0;
		}

		.slick-dots {
			padding: 0;
			left: 125px;
		}
	}
}
