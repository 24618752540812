@font-face {
	font-family: 'icomoon';
	src:  url('/Cwp/CCS/assets/fonts/icomoon.eot?njcxuy');
	src:  url('/Cwp/CCS/assets/fonts/icomoon.eot?njcxuy#iefix') format('embedded-opentype'),
  url('/Cwp/CCS/assets/fonts/icomoon.ttf?njcxuy') format('truetype'),
  url('/Cwp/CCS/assets/fonts/icomoon.woff?njcxuy') format('woff'),
  url('/Cwp/CCS/assets/fonts/icomoon.svg?njcxuy#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
  vertical-align: initial;


	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

  &:before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: initial;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

%icomoon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: initial;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-print:before {
  content: "\e94c";
}
.icon-quotes:before {
  content: "\e94a";
}
.icon-file-a-complaint .path1:before {
  content: "\e922";
  color: rgb(16, 103, 178);
}
.icon-file-a-complaint .path2:before {
  content: "\e923";
  margin-left: -1.0380859375em;
  color: rgb(252, 185, 20);
}
.icon-file-a-complaint .path3:before {
  content: "\e924";
  margin-left: -1.0380859375em;
  color: rgb(187, 187, 187);
}
.icon-file-a-complaint .path4:before {
  content: "\e925";
  margin-left: -1.0380859375em;
  color: rgb(187, 187, 187);
}
.icon-file-a-complaint .path5:before {
  content: "\e926";
  margin-left: -1.0380859375em;
  color: rgb(187, 187, 187);
}
.icon-file-a-complaint .path6:before {
  content: "\e927";
  margin-left: -1.0380859375em;
  color: rgb(187, 187, 187);
}
.icon-file-a-complaint .path7:before {
  content: "\e928";
  margin-left: -1.0380859375em;
  color: rgb(187, 187, 187);
}
.icon-file-a-complaint .path8:before {
  content: "\e929";
  margin-left: -1.0380859375em;
  color: rgb(16, 103, 178);
}
.icon-merger .path1:before {
  content: "\e92a";
  color: rgb(18, 104, 178);
}
.icon-merger .path2:before {
  content: "\e92b";
  margin-left: -1.4912109375em;
  color: rgb(18, 104, 178);
}
.icon-merger .path3:before {
  content: "\e92c";
  margin-left: -1.4912109375em;
  color: rgb(18, 104, 178);
}
.icon-merger .path4:before {
  content: "\e92d";
  margin-left: -1.4912109375em;
  color: rgb(18, 104, 178);
}
.icon-merger .path5:before {
  content: "\e92e";
  margin-left: -1.4912109375em;
  color: rgb(18, 104, 178);
}
.icon-merger .path6:before {
  content: "\e92f";
  margin-left: -1.4912109375em;
  color: rgb(18, 104, 178);
}
.icon-merger .path7:before {
  content: "\e930";
  margin-left: -1.4912109375em;
  color: rgb(251, 184, 23);
}
.icon-merger .path8:before {
  content: "\e931";
  margin-left: -1.4912109375em;
  color: rgb(251, 184, 23);
}
.icon-merger .path9:before {
  content: "\e932";
  margin-left: -1.4912109375em;
  color: rgb(251, 184, 23);
}
.icon-merger .path10:before {
  content: "\e933";
  margin-left: -1.4912109375em;
  color: rgb(251, 184, 23);
}
.icon-merger .path11:before {
  content: "\e934";
  margin-left: -1.4912109375em;
  color: rgb(251, 184, 23);
}
.icon-merger .path12:before {
  content: "\e935";
  margin-left: -1.4912109375em;
  color: rgb(251, 184, 23);
}
.icon-abuse-of-dominance .path1:before {
  content: "\e906";
  color: rgb(16, 103, 178);
}
.icon-abuse-of-dominance .path2:before {
  content: "\e90e";
  margin-left: -1.1484375em;
  color: rgb(252, 185, 20);
}
.icon-abuse-of-dominance .path3:before {
  content: "\e90f";
  margin-left: -1.1484375em;
  color: rgb(252, 185, 20);
}
.icon-abuse-of-dominance .path4:before {
  content: "\e910";
  margin-left: -1.1484375em;
  color: rgb(252, 185, 20);
}
.icon-abuse-of-dominance .path5:before {
  content: "\e911";
  margin-left: -1.1484375em;
  color: rgb(187, 187, 187);
}
.icon-anti-competitive-agreement .path1:before {
  content: "\e912";
  color: rgb(252, 185, 20);
}
.icon-anti-competitive-agreement .path2:before {
  content: "\e913";
  margin-left: -1.521484375em;
  color: rgb(16, 103, 178);
}
.icon-apply-for-leniency .path1:before {
  content: "\e914";
  color: rgb(16, 103, 178);
}
.icon-apply-for-leniency .path2:before {
  content: "\e915";
  margin-left: -0.7412109375em;
  color: rgb(252, 185, 20);
}
.icon-apply-for-leniency .path3:before {
  content: "\e916";
  margin-left: -0.7412109375em;
  color: rgb(252, 185, 20);
}
.icon-apply-for-leniency .path4:before {
  content: "\e917";
  margin-left: -0.7412109375em;
  color: rgb(252, 185, 20);
}
.icon-apply-for-leniency .path5:before {
  content: "\e918";
  margin-left: -0.7412109375em;
  color: rgb(252, 185, 20);
}
.icon-apply-for-leniency .path6:before {
  content: "\e919";
  margin-left: -0.7412109375em;
  color: rgb(152, 157, 159);
}
.icon-apply-for-leniency .path7:before {
  content: "\e91a";
  margin-left: -0.7412109375em;
  color: rgb(187, 187, 187);
}
.icon-apply-for-leniency .path8:before {
  content: "\e91b";
  margin-left: -0.7412109375em;
  color: rgb(187, 187, 187);
}
.icon-apply-for-leniency .path9:before {
  content: "\e91c";
  margin-left: -0.7412109375em;
  color: rgb(152, 157, 159);
}
.icon-calendar .path1:before {
  content: "\e91d";
  color: rgb(16, 103, 178);
}
.icon-calendar .path2:before {
  content: "\e91e";
  margin-left: -0.9755859375em;
  color: rgb(252, 185, 20);
}
.icon-calendar .path3:before {
  content: "\e91f";
  margin-left: -0.9755859375em;
  color: rgb(252, 185, 20);
}
.icon-consumer-protection .path1:before {
  content: "\e920";
  color: rgb(252, 185, 20);
}
.icon-consumer-protection .path2:before {
  content: "\e921";
  margin-left: -0.8359375em;
  color: rgb(16, 103, 178);
}
.icon-public-consultation .path1:before {
  content: "\e936";
  color: rgb(16, 103, 178);
}
.icon-public-consultation .path2:before {
  content: "\e937";
  margin-left: -0.748046875em;
  color: rgb(16, 103, 178);
}
.icon-public-consultation .path3:before {
  content: "\e938";
  margin-left: -0.748046875em;
  color: rgb(252, 185, 20);
}
.icon-public-consultation .path4:before {
  content: "\e939";
  margin-left: -0.748046875em;
  color: rgb(252, 185, 20);
}
.icon-public-consultation .path5:before {
  content: "\e93a";
  margin-left: -0.748046875em;
  color: rgb(252, 185, 20);
}
.icon-seek-advice .path1:before {
  content: "\e93b";
  color: rgb(187, 187, 187);
}
.icon-seek-advice .path2:before {
  content: "\e93c";
  margin-left: -1.2412109375em;
  color: rgb(16, 103, 178);
}
.icon-seek-advice .path3:before {
  content: "\e93d";
  margin-left: -1.2412109375em;
  color: rgb(252, 185, 20);
}
.icon-seek-advice .path4:before {
  content: "\e93e";
  margin-left: -1.2412109375em;
  color: rgb(252, 185, 20);
}
.icon-seek-advice .path5:before {
  content: "\e93f";
  margin-left: -1.2412109375em;
  color: rgb(252, 185, 20);
}
.icon-seeking-guidance .path1:before {
  content: "\e940";
  color: rgb(16, 103, 178);
}
.icon-seeking-guidance .path2:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(16, 103, 178);
}
.icon-seeking-guidance .path3:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(252, 185, 20);
}
.icon-seeking-guidance .path4:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(152, 157, 159);
}
.icon-seeking-guidance .path5:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(187, 187, 187);
}
.icon-seeking-guidance .path6:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(187, 187, 187);
}
.icon-seeking-guidance .path7:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(187, 187, 187);
}
.icon-seeking-guidance .path8:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(187, 187, 187);
}
.icon-seeking-guidance .path9:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(187, 187, 187);
}
.icon-home:before {
  content: "\e909";
}
.icon-pdf:before {
  content: "\e90a";
}
.icon-chevron-down:before {
  content: "\e90b";
}
.icon-chevron-up:before {
  content: "\e900";
}
.icon-chevron-right:before {
  content: "\e901";
}
.icon-chevron-left:before {
  content: "\e902";
}
.icon-circle-down:before {
  content: "\e949";
}
.icon-arrow-right:before {
  content: "\e903";
}
.icon-facebook:before {
  content: "\e904";
}
.icon-bell:before {
  content: "\e90c";
}
.icon-youtube:before {
  content: "\e905";
}
.icon-twitter:before {
  content: "\e907";
}
.icon-mail:before {
  content: "\e90d";
}
.icon-search:before {
  content: "\e908";
}
