// video-gallery-widget styles
.video-gallery {
	.row {
		margin: 0 -20px;
	}

	.rte {
		padding-left: 0;
		margin-bottom: 35px;
	}
}

@media only screen and (min-width: $bpDesktop) {
	.video-gallery {
		padding: 0 0 0 55px;
	}
}
